module.exports = {
  "language": "Valoda",
  "actors": "Aktieri",
  "ID": "Personas apliecība",
  "search": "Meklēt",
  "copied": "Kopē pie starpliktuves",
  "sort": "Kārtot {0}",
  "yes": "Jā",
  "no": "Nē",
  "notifications": {
    "title": "Paziņojumi",
    "empty": "Jums nav paziņojumu"
  },
  "auth": {
    "logout": "Izlogoties",
    "login": "Pieslēgties",
    "register": "Reģistrējiet jaunu kontu"
  },
  "user": {
    "changePassword": "Mainīt paroli",
    "firstPasswordChange": "\"Jums ir jāmaina parole",
    "plural": "Lietotāji",
    "single": "Lietotājs",
    "filters": {
      "locked": "Rādīt bloķētus lietotājus"
    },
    "fields": {
      "password": "Parole",
      "authorizations": "Autorizācijas",
      "email": "E -pasts",
      "name": "Nosaukt",
      "repeatPassword": "atkārtojiet paroli"
    },
    "validation": {
      "length": "8 rakstzīmes gari",
      "uppercase": "Viens lielais burts",
      "lowercase": "Viens mazais burts",
      "number": "Viens numurs",
      "special": "Viens īpašais raksturs (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Telefons",
      "company": "Kompānijas nosaukums",
      "street": "Iela",
      "zipCode": "Pasta indekss",
      "city": "Pilsēta",
      "country": "Apvidus",
      "houseNumber": "mājas numurs"
    }
  },
  "errors": {
    "loginWrong": "Användarnamn och lösenord passar inte. Var god försök igen!",
    "notFound": {
      "title": "Lapa nav atrasta",
      "message": "Hmm! Šī lapa netika atrasta."
    },
    "noInternet": "Hai accettato il carico senza una connessione Internet attiva. \\ NPlease Controllare le impostazioni dei dati WiFi o mobili. \\ Nonce La connessione viene ristabilita dall'aggiornamento sarà risentito automaticamente"
  },
  "messages": {
    "sureDelete": " что хотите удалить {0}?\"",
    "sureSave": " что хотите сохранить {0}?\""
  },
  "tradelane": {
    "fields": {
      "sender": "Sūtītājs",
      "receiver": "Uztvērējs"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Numurzīme"
    }
  },
  "company": {
    "single": "Kompānija"
  },
  "scan": {
    "currentStage": "Pašreizējais posms",
    "description": "Apraksts",
    "qrCode": "QR kods",
    "locationNotAccessible": "Atrašanās vieta nav atrasta",
    "noEmballage": "Nav iepakojuma",
    "noMessage": "Nav pievienots ziņojums",
    "missingCargo": "Trūkst kravas",
    "shipmentAndBarcodes": "Sūtījums un svītrkodi",
    "scanPackages": "Skenēšanas paketes",
    "emballage": "Dumpināt",
    "required": "Prasīgs",
    "upload": "Augšupielādēt",
    "statusRequirements": "Statusa prasības",
    "addPhoto": "Pievienot fotoattēlu",
    "addAttachments": "Pievienot pielikumus",
    "addMessage": "Pievienot ziņojumu",
    "addEmballage": "Pievienot lāpstiņu",
    "signOnGlass": "Zīme uz stikla",
    "start": "Sākt",
    "shelvesExtensions": "Plaukti un pagarinājumi",
    "notready": "Nav gatavs",
    "manco": "Trūkstošs",
    "fields": {
      "message": "Ziņot",
      "attach": "Savienot",
      "detach": "Atvienot"
    },
    "changeAmounts": "Mainīt summas",
    "damage": "Sabojāt",
    "not": "Ne",
    "mancoMessage": "En voinut skannata viivakoodia tai pakkausta? Ilmoita siitä tällä alalla ..."
  },
  "sign": {
    "sign": "Apzīmēt",
    "signAbsence": "Parakstīts bez klienta"
  },
  "erp": {
    "single": "ERP",
    "packaging": "Iesaiņojums"
  },
  "nav": {
    "finished": "Pabeigts",
    "current": "Strāva",
    "overview": "Pārskats",
    "messages": "Ziņojumi",
    "shipment": "Sūtījums",
    "shipments": "Sūtījumi",
    "settings": "Iestatījumi",
    "planning": "Bezdarbs",
    "report": "Ziņojumi"
  },
  "wallet": {
    "single": "Maciņš"
  },
  "common": {
    "info": "Informācija",
    "status": "Statuss",
    "location": "Atrašanās vieta",
    "contact": "Saskare",
    "contacts": "Kontakti",
    "photos": "Fotografēt",
    "attachments": "Pielikumi",
    "identifier": "Identifikators",
    "type": "Ierakstīt",
    "actions": "Darbības",
    "name": "Nosaukt",
    "value": "Novērtēt",
    "tradelane": "Tirgotava",
    "date": "Datums",
    "actor": "Aktieris",
    "create": "Radīt",
    "from": "No",
    "to": "Līdz",
    "general": "Ģenerāldirektors",
    "back": "Aizmugure",
    "next": "Nākamais",
    "finish": "Pabeigt",
    "clear": "Noskaidrot",
    "notes": "Piezīmes",
    "details": "Detaļa",
    "retry": "Atkārtot",
    "amount": "Summa",
    "trip": "Braukt",
    "references": "Atsauces",
    "in": "Iekšā",
    "out": "Ārpusē",
    "senderMessage": "Sūtītāja piezīme",
    "receiverMessage": "Uztvērēja piezīme",
    "total": "Kopā"
  },
  "actions": {
    "shipmentCreated": "Izveidots sūtījums",
    "shipmentUpdated": "Sūtījums atjaunināts",
    "planningCreated": "Izveidota plānošana",
    "planningUpdated": "Plānošana atjaunināta",
    "accesscodeCreated": "Izveidots piekļuves kods",
    "accesscodeShared": "Piekļuves kods koplietots",
    "notificationSent": "Nosūtīts paziņojums",
    "partOfAccesscodeCreated": "Izveidotās piekļuves koda elements plānošanai",
    "partOfAccesscodeShared": "Koplietotās piekļuves koda elements plānošanai",
    "addObj": "Pievienot {0}",
    "add": "Pievienot",
    "save": "Ietaupīt",
    "saveObj": "Saglabāt {0}",
    "copy": "Kopija",
    "send": "Nosūtīt",
    "continue": "Turpināt",
    "close": "Tuvs"
  },
  "shipment": {
    "eta": "Eta",
    "ata": "Ata",
    "single": "Sūtījums",
    "status": "Statuss",
    "finished": "Pabeigti sūtījumi",
    "pickup": "Pacelt",
    "delivery": "Piegāde",
    "delivered": "Piegāde",
    "pickedUp": "Piegādāts",
    "fields": {
      "parties": "Ballītes",
      "createdAt": "Izveidots",
      "updatedAt": "Atjaunināts plkst"
    },
    "consignment": {
      "single": "Sūtījums",
      "scanned": {
        "waiting": "{Id} Lagret! \\ NSTORING DATA kan ta litt tid. \\ Nyou kan fortsette med en annen forsendelse."
      }
    },
    "containers": {
      "fields": {
        "hsCode": "HS kods",
        "packQuantity": "Iesaiņojuma daudzums",
        "packSize": "Iesaiņojuma izmērs",
        "weight": "Svars"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Satur bīstamas preces"
      }
    },
    "documents": {
      "fields": {
        "file": "Fails"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Kravas cena"
      }
    },
    "lastStatus": "Pēdējais statuss"
  },
  "accept": {
    "updateShipment": "Atjaunināt sūtījumu",
    "zeroEmballageMessage": "Du har inte angett någon förpackning. Ange en anledning nedan eller klicka på Finish."
  },
  "apiKeys": {
    "plural": "API atslēgas",
    "single": "API atslēga",
    "fields": {
      "key": "Atslēga"
    }
  },
  "planning": {
    "driverInformation": "Informācija par vadītāju"
  },
  "from": "No",
  "for": "Priekš",
  "commissioned_by": "Pasūtījis",
  "planning_finished": "Pasūtījis",
  "sure_question": "Pasūtījis",
  "sure_question_cancel": "Jūs atceļat aktīvu pieturu. Vai tu esi pārliecināts?"
}