import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from '@/stores'
import './registerServiceWorker'
import './plugins/axios'
import './plugins/declerations'
import './plugins/global-components'
import './plugins/globals'
import './plugins/utils'
import './plugins/websocket'
import './plugins/vee-validate'
import './plugins/vue-lazyload'
import vuetify from './plugins/vuetify'
import './plugins/filters'
import i18n from './i18n'
import 'leaflet/dist/leaflet.css';
import firebaseMessaging from 'firebase/messaging'

Vue.config.productionTip = false

import { Icon } from 'leaflet'
import {apolloProvider} from "@/plugins/Apollo";
import firebase from "firebase/compat";
import initializeApp = firebase.initializeApp;
//@ts-ignore
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

const title = window.location.hostname.split('.')[0]
window.document.title = title.charAt(0).toUpperCase() + title.slice(1)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
