module.exports = {
  "language": "Idioma",
  "actors": "Actores",
  "ID": "IDENTIFICACIÓN",
  "search": "Búsqueda",
  "copied": "Copiado al portapapeles",
  "sort": "Ordenar {0}",
  "yes": "Sí",
  "no": "No",
  "notifications": {
    "title": "Notificaciones",
    "empty": "no tienes notificaciones"
  },
  "auth": {
    "logout": "Cerrar sesión",
    "login": "Acceso",
    "register": "Registrar una cuenta nueva"
  },
  "user": {
    "changePassword": "Cambia la contraseña",
    "firstPasswordChange": "Debe cambiar su contraseña en el primer inicio de sesión",
    "plural": "Usuarios",
    "single": "Usuario",
    "filters": {
      "locked": "Mostrar usuarios bloqueados"
    },
    "fields": {
      "password": "Clave",
      "authorizations": "Autorizaciones",
      "email": "Correo electrónico",
      "name": "Nombre",
      "repeatPassword": "Repita la contraseña"
    },
    "validation": {
      "length": "8 caracteres largo",
      "uppercase": "Una letra mayúscula",
      "lowercase": "Una letra minúscula",
      "number": "Un número",
      "special": "Un personaje especial (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Teléfono",
      "company": "Nombre de empresa",
      "street": "Calle",
      "zipCode": "Código postal",
      "city": "Ciudad",
      "country": "País",
      "houseNumber": "número de casa"
    }
  },
  "errors": {
    "loginWrong": "Nazwa użytkownika i hasło nie pasują do siebie. Proszę spróbuj ponownie!",
    "notFound": {
      "title": "Página no encontrada",
      "message": "¡Ups! Esta página no se encontró."
    },
    "noInternet": " at opdateringen vil være med at vrede automatisk\""
  },
  "messages": {
    "sureDelete": "\"Сигурни ли сте",
    "sureSave": "\"Сигурни ли сте"
  },
  "tradelane": {
    "fields": {
      "sender": "Remitente",
      "receiver": "Receptor"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Placa"
    }
  },
  "company": {
    "single": "Compañía"
  },
  "scan": {
    "currentStage": "Etapa actual",
    "description": "Descripción",
    "qrCode": "Código QR",
    "locationNotAccessible": "Ubicación no encontrada",
    "noEmballage": "Sin embalaje",
    "noMessage": "No se agregó ningún mensaje",
    "missingCargo": "Cargo faltante",
    "shipmentAndBarcodes": "Envío y códigos de barras",
    "scanPackages": "Paquetes de escaneo",
    "emballage": "Embalaje",
    "required": "Requerido",
    "upload": "Subir",
    "statusRequirements": "Requisitos de estado",
    "addPhoto": "Añadir foto",
    "addAttachments": "Agregar archivos adjuntos",
    "addMessage": "Agregar mensaje",
    "addEmballage": "Agregar embalaje",
    "signOnGlass": "Señalización de vidrio",
    "start": "comienzo",
    "shelvesExtensions": "Estantes y extensiones",
    "notready": "No está listo",
    "manco": "Perdido",
    "fields": {
      "message": "Mensaje",
      "attach": "Conectar",
      "detach": "Desconectar"
    },
    "changeAmounts": "Cambiar cantidades",
    "damage": "Daño",
    "not": "No",
    "mancoMessage": "¿No se pudo escanear el código de barras o el paquete no presente? Informarlo en este campo ..."
  },
  "sign": {
    "sign": "Señal",
    "signAbsence": "Firmado en ausencia de cliente"
  },
  "erp": {
    "single": "ERP",
    "packaging": "embalaje"
  },
  "nav": {
    "finished": "Acabado",
    "current": "Actual",
    "overview": "Visión general",
    "messages": "Mensajes",
    "shipment": "Envío",
    "shipments": "Envío",
    "settings": "Ajustes",
    "planning": "Lista de trabajo",
    "report": "Informes"
  },
  "wallet": {
    "single": "Cartera"
  },
  "common": {
    "info": "Información",
    "status": "Estado",
    "location": "Ubicación",
    "contact": "Contacto",
    "contacts": "Contactos",
    "photos": "Fotos",
    "attachments": "Archivos adjuntos",
    "identifier": "Identificador",
    "type": "Escribe",
    "actions": "Comportamiento",
    "name": "Nombre",
    "value": "Valor",
    "tradelane": "Ruta comercial",
    "date": "Fecha",
    "actor": "Actor",
    "create": "Crear",
    "from": "De",
    "to": "A",
    "general": "General",
    "back": "atrás",
    "next": "próximo",
    "finish": "Finalizar",
    "clear": "Claro",
    "notes": "Notas",
    "details": "Detalles",
    "retry": "Rever",
    "amount": "Monto",
    "trip": "Viaje",
    "references": "Referencias",
    "in": "En",
    "out": "Afuera",
    "senderMessage": "Comentario del remitente",
    "receiverMessage": "Comentario del receptor",
    "total": "Total"
  },
  "actions": {
    "shipmentCreated": "Envío creado",
    "shipmentUpdated": "Envío actualizado",
    "planningCreated": "Planificación creada",
    "planningUpdated": "Planificación actualizada",
    "accesscodeCreated": "Código de acceso creado",
    "accesscodeShared": "Código de acceso compartido",
    "notificationSent": "Notificación enviada",
    "partOfAccesscodeCreated": "Elemento del código de acceso creado para la planificación",
    "partOfAccesscodeShared": "Elemento del código de acceso compartido para la planificación",
    "addObj": "Agregar {0}",
    "add": "Agregar",
    "save": "Ahorrar",
    "saveObj": "Guardar {0}",
    "copy": "Copiar",
    "send": "Enviar",
    "continue": "Continuar",
    "close": "Cerca"
  },
  "shipment": {
    "eta": "ETA",
    "ata": "Ata",
    "single": "Envío",
    "status": "Estado",
    "finished": "Envíos finalizados",
    "pickup": "Levantar",
    "delivery": "Entrega",
    "delivered": "Entrega",
    "pickedUp": "Entregado",
    "fields": {
      "parties": "Fiestas",
      "createdAt": "Creado en",
      "updatedAt": "Actualizado en"
    },
    "consignment": {
      "single": "Envío",
      "scanned": {
        "waiting": "{id} Запазени! \\ nstoring Данните могат да отнемат известно време. \\ ny можете да продължите с друга пратка."
      }
    },
    "containers": {
      "fields": {
        "hsCode": "Código hs",
        "packQuantity": "Cantidad de paquete",
        "packSize": "Tamaño del paquete",
        "weight": "Peso"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Contiene bienes peligrosos"
      }
    },
    "documents": {
      "fields": {
        "file": "Expediente"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Precio de flete"
      }
    },
    "lastStatus": "Último estado"
  },
  "accept": {
    "updateShipment": "Actualizar envío",
    "zeroEmballageMessage": " посочете причина по -долу или щракнете върху Finish.\""
  },
  "apiKeys": {
    "plural": "Llaves de API",
    "single": "Clave API",
    "fields": {
      "key": "Llave"
    }
  },
  "planning": {
    "driverInformation": "Información del conductor"
  },
  "from": "De",
  "for": "Para",
  "commissioned_by": "Encargado por",
  "planning_finished": "Planificación terminada",
  "sure_question": "¿Está seguro?",
  "sure_question_cancel": "Estás cancelando una parada activa. ¿Está seguro?"
}