/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Action {
  ATTACH = "ATTACH",
  DETACH = "DETACH",
  LOAD = "LOAD",
  UNLOAD = "UNLOAD",
}

export enum Authorities {
  ACCESS_CODE = "ACCESS_CODE",
  ADMIN = "ADMIN",
  COMPANY = "COMPANY",
  SHIPMENT = "SHIPMENT",
  TRANSPORT = "TRANSPORT",
  TRIGGER = "TRIGGER",
  USER = "USER",
}

export enum Currency {
  DOLLAR = "DOLLAR",
  EURO = "EURO",
  SWISS_FRANC = "SWISS_FRANC",
}

export enum DocumentType {
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  SIGNATURE = "SIGNATURE",
  VAGD = "VAGD",
  eCMR = "eCMR",
}

export enum ECMRAction {
  DELIVERY = "DELIVERY",
  PICKUP = "PICKUP",
}

export enum ExciseStatusType {
  DECLARED = "DECLARED",
  DELIVERED = "DELIVERED",
  PERMISSION = "PERMISSION",
  REFUNDED = "REFUNDED",
  REPORTED = "REPORTED",
}

export enum ImportConversion {
  BBGREENLOGISTIK_TRANSFER = "BBGREENLOGISTIK_TRANSFER",
  BERGEIJK_TRANSFER = "BERGEIJK_TRANSFER",
  BOSDAALEN_TRANSFER = "BOSDAALEN_TRANSFER",
  COBANA_FRACHTAUFTRAGE = "COBANA_FRACHTAUFTRAGE",
  DOUANE_FORWARDING_INSTRUCTIONS = "DOUANE_FORWARDING_INSTRUCTIONS",
  FDERIDDER_TRANSFER = "FDERIDDER_TRANSFER",
  GETRU_TRIPDATA = "GETRU_TRIPDATA",
  GRAPHQL_SHIPMENT = "GRAPHQL_SHIPMENT",
  GRAPHQL_STATUS = "GRAPHQL_STATUS",
  HZLOGISTICS_TRANSFER = "HZLOGISTICS_TRANSFER",
  OLDENBURGER_EMBALLAGE = "OLDENBURGER_EMBALLAGE",
  OLDENBURGER_HUKRA = "OLDENBURGER_HUKRA",
  OLDENBURGER_NORGE_TRANSFER = "OLDENBURGER_NORGE_TRANSFER",
  OLDENBURGER_TRANSFER = "OLDENBURGER_TRANSFER",
  SLUYTER_TRANSFER = "SLUYTER_TRANSFER",
  UBL_FORWARDING_INSTRUCTIONS = "UBL_FORWARDING_INSTRUCTIONS",
  VANOERS_ORDER = "VANOERS_ORDER",
  VDHLOGISTICS_TRANSFER = "VDHLOGISTICS_TRANSFER",
}

export enum MessageType {
  ACCESS_CODE_CREATED = "ACCESS_CODE_CREATED",
  ACCESS_CODE_SHARED = "ACCESS_CODE_SHARED",
  ECMR = "ECMR",
  EXCISE = "EXCISE",
  NOTIFICATION_SENT = "NOTIFICATION_SENT",
  PART_OF_ACCESS_CODE_CREATED = "PART_OF_ACCESS_CODE_CREATED",
  PART_OF_ACCESS_CODE_SHARED = "PART_OF_ACCESS_CODE_SHARED",
  PLANNING_CREATED = "PLANNING_CREATED",
  PLANNING_UPDATED = "PLANNING_UPDATED",
  SHIPMENT_CREATED = "SHIPMENT_CREATED",
  SHIPMENT_UPDATED = "SHIPMENT_UPDATED",
}

export enum NotificationMedium {
  EMAIL = "EMAIL",
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
  SMS = "SMS",
}

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export enum Requirement {
  ACTIVITY_STARTED = "ACTIVITY_STARTED",
  ARRIVAL = "ARRIVAL",
  DOCUMENT = "DOCUMENT",
  EMBALLAGE = "EMBALLAGE",
  EMBALLAGE_DELIVERED = "EMBALLAGE_DELIVERED",
  EMBALLAGE_PICKED_UP = "EMBALLAGE_PICKED_UP",
  FINISH_PLANNING = "FINISH_PLANNING",
  IMAGE = "IMAGE",
  MANUAL_ACCEPT_CHECK = "MANUAL_ACCEPT_CHECK",
  MESSAGE = "MESSAGE",
  MODIFY_CONTAINER_QUANTITIES = "MODIFY_CONTAINER_QUANTITIES",
  MODIFY_SHELVES_EXTENSIONS = "MODIFY_SHELVES_EXTENSIONS",
  SCAN = "SCAN",
  SIGNATURE = "SIGNATURE",
  SPECIFY_DECLINED_STATE = "SPECIFY_DECLINED_STATE",
  TEMPERATURE = "TEMPERATURE",
}

export enum RequirementLevel {
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED",
  REQUIRED_IF_DAMAGED = "REQUIRED_IF_DAMAGED",
  REQUIRED_IF_NOT_ACCEPTED = "REQUIRED_IF_NOT_ACCEPTED",
}

export enum StatusState {
  ACCEPTED = "ACCEPTED",
  DAMAGED = "DAMAGED",
  DECLINED = "DECLINED",
  MANCO = "MANCO",
  NOT_READY = "NOT_READY",
}

export enum StatusType {
  CROSSDOCK_DELIVERY = "CROSSDOCK_DELIVERY",
  CROSSDOCK_DELIVERY_STARTED = "CROSSDOCK_DELIVERY_STARTED",
  CROSSDOCK_DELIVERY_STARTED_CANCELED = "CROSSDOCK_DELIVERY_STARTED_CANCELED",
  CROSSDOCK_PICKUP = "CROSSDOCK_PICKUP",
  CROSSDOCK_PICKUP_STARTED = "CROSSDOCK_PICKUP_STARTED",
  CROSSDOCK_PICKUP_STARTED_CANCELED = "CROSSDOCK_PICKUP_STARTED_CANCELED",
  DELIVERY = "DELIVERY",
  DELIVERY_STARTED = "DELIVERY_STARTED",
  DELIVERY_STARTED_CANCELED = "DELIVERY_STARTED_CANCELED",
  DOCK_ASSIGNED = "DOCK_ASSIGNED",
  ON_ROUTE = "ON_ROUTE",
  PICKUP = "PICKUP",
  PICKUP_STARTED = "PICKUP_STARTED",
  PICKUP_STARTED_CANCELED = "PICKUP_STARTED_CANCELED",
  PLANNING_PROCESSED = "PLANNING_PROCESSED",
  UPDATE_ACCEPTED = "UPDATE_ACCEPTED",
}

export enum VehicleType {
  TRAILER = "TRAILER",
  TRUCK = "TRUCK",
}

export interface AccessCodeNotificationCreate {
  medium: NotificationMedium;
  message?: string | null;
  recipient: string;
}

export interface ActionUpdateModel {
  address: AddressUpdateModel;
  eta?: any | null;
  etd?: any | null;
  id: number;
  identifier?: string | null;
  instructions?: string | null;
  person?: PersonUpdateModel | null;
  requirements?: StatusRequirementCreateModel[] | null;
  status?: StatusUpdateModel | null;
}

export interface ActorUpdateModel {
  company: ShipmentCompanyUpdateModel;
  delivery: ActionUpdateModel;
  id: number;
  pickup: ActionUpdateModel;
  price?: MoneyUpdateModel | null;
  progresses?: ProgressUpdateModel[] | null;
}

export interface AddressUpdateModel {
  city?: string | null;
  coordinates?: string | null;
  country?: string | null;
  countryCode?: string | null;
  id: number;
  name?: string | null;
  number?: string | null;
  street?: string | null;
  zipcode?: string | null;
}

export interface BundledStatusCreateModel {
  deliveredEmballage?: MapStringIntInput | null;
  dimensions: DimensionCreateModel[];
  documents?: number[] | null;
  handover: HandoverCreateModel;
  identifier: string;
  location?: string | null;
  message?: string | null;
  mutations?: MapStringStringInput | null;
  person?: PersonCreateModel | null;
  pickedUpEmballage?: MapStringIntInput | null;
  planningData?: PlanningDataCreateModel | null;
  state: StatusState;
  type: StatusType;
}

export interface BundledStatusesCreateModel {
  documents: DocumentCreateModel[];
  statusCreateModels: StatusCreate[];
}

export interface BundledStatusesIDCreateModel {
  statusCreateModels: StatusIDCreateModel[];
  documents: DocumentCreateModel[];
}

export interface ChangePasswordModel {
  id: number;
  password: any;
}

export interface ConsignmentUpdateModel {
  actors: ActorUpdateModel[];
  containers: ContainerUpdateModel[];
  customs?: string | null;
  documents?: DocumentUpdateModel[] | null;
  id: number;
  incoterms?: string | null;
  indicators?: string[] | null;
  name: string;
  receiver: ShipmentCompanyUpdateModel;
  references?: string[] | null;
  sender: ShipmentCompanyUpdateModel;
}

export interface ContainerTypeCreateModel {
  size?: string | null;
  type?: string | null;
}

export interface ContainerUpdateModel {
  barcode?: string | null;
  dimensions?: DimensionUpdateModel[] | null;
  id: number;
  identifier?: string | null;
  packets: PacketUpdateModel[];
  properties?: MapStringStringInput | null;
  quantity?: any | null;
  type?: ContainerTypeCreateModel | null;
}

export interface CreateAccessCodePlanningModel {
  description?: string | null;
  entity: number;
  validTill?: any | null;
}

export interface CreateUserModel {
  authorities: Authorities[];
  email: any;
  locked: boolean;
  password: any;
}

export interface DimensionCreateModel {
  attributeId?: string | null;
  unitCode?: string | null;
  value: string;
}

export interface DimensionUpdateModel {
  attributeId?: string | null;
  id: number;
  unitCode?: string | null;
  value: string;
}

export interface DocumentCreateModel {
  base64: string;
  meta?: string | null;
  mime: string;
  name: string;
  type: DocumentType;
}

export interface DocumentUpdateModel {
  base64: string;
  id: number;
  meta?: string | null;
  mime: string;
  name: string;
  type: DocumentType;
}

export interface GenericImport {
  conversionType: ImportConversion;
  data: string;
}

export interface HandoverCreateModel {
  arrival?: any | null;
  finish?: any | null;
  start?: any | null;
}

export interface ItemUpdateModel {
  description?: string | null;
  hscode?: string | null;
  id: number;
  identifier?: string | null;
  properties?: MapStringStringInput | null;
  size?: number | null;
}

export interface LoginUserModel {
  email: any;
  password: any;
}

export interface MapStringIntInput {
  items: PairStringIntInput[];
}

export interface MapStringStringInput {
  items: PairStringStringInput[];
}

export interface MoneyUpdateModel {
  amount: any;
  currency: Currency;
  date: any;
  id: number;
}

export interface PacketUpdateModel {
  description?: string | null;
  dimensions?: DimensionUpdateModel[] | null;
  id: number;
  identifier?: string | null;
  items: ItemUpdateModel[];
  monetary?: MoneyUpdateModel | null;
  quantity?: any | null;
}

export interface Pagination {
  itemsPerPage: number;
  page: number;
  period?: Period | null;
  search?: string | null;
  sort?: Sort | null;
}

export interface PairStringIntInput {
  key: string;
  value: number;
}

export interface PairStringStringInput {
  key: string;
  value: string;
}

export interface Period {
  from: any;
  to: any;
}

export interface PersonCreateModel {
  email?: any | null;
  message?: string | null;
  name?: string | null;
  phone?: string | null;
}

export interface PersonUpdateModel {
  email?: any | null;
  id: number;
  message?: string | null;
  name?: string | null;
  phone?: string | null;
}

export interface PlanningDataCreateModel {
  activityIdentifier: string;
  stopIdentifier: string;
  tripIdentifier: string;
}

export interface ProgressUpdateModel {
  id: number;
  status: StatusUpdateModel;
}

export interface ShipmentCompanyUpdateModel {
  actorId: string;
  address: AddressUpdateModel;
  cargoledger?: number | null;
  id: number;
  name: string;
  person?: PersonUpdateModel | null;
  shared?: any | null;
}

export interface Sort {
  by: string;
  order: Order;
}

export interface StatusCreate {
  status: BundledStatusCreateModel;
}

export interface StatusIDCreateModel {
  consignment: string;
  expeditor: number;
  shipment: string;
  status: BundledStatusCreateModel;
}

export interface StatusRequirementCreateModel {
  global: boolean;
  level: RequirementLevel;
  requirement: Requirement;
  text?: string | null;
}

export interface StatusUpdateModel {
  deliveredEmballage?: MapStringIntInput | null;
  dimensions: DimensionUpdateModel[];
  documents?: DocumentUpdateModel[] | null;
  handover: HandoverCreateModel;
  id: number;
  identifier: string;
  location?: string | null;
  message?: string | null;
  mutations?: MapStringStringInput | null;
  person?: PersonUpdateModel | null;
  pickedUpEmballage?: MapStringIntInput | null;
  planningData?: PlanningDataCreateModel | null;
  state: StatusState;
  type: StatusType;
}

export interface UpdateUserModel {
  authorities: Authorities[];
  email: any;
  id: number;
  locked: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
