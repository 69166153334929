







import { Component, Inject, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'

@Component({
  components: {
    ValidationObserver
  }
})
export default class BaseForm extends Vue {
  @Inject() errorMessage: string

  validator(): any {
    return this.$refs.validator as any
  }
}
