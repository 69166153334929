import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider
  }
})
export default class InputMixin extends Vue {
  @Prop() value!: any
  @Prop() label!: string
  @Prop() id!: string
  @Prop({ required: false }) vid: string | null
  @Prop({
    default: () => {}
  })
  validator: any
  @Prop() icon!: string
  @Prop({ default: true }) translate: boolean

  @Inject({ default: false }) protected objectValidator!: any

  get compValidator() {
    if (this.objectValidator) {
      if (this.vid && this.vid in this.objectValidator) {
        return Object.assign({}, this.objectValidator[this.vid], this.validator)
      } else if (this.id in this.objectValidator) {
        return Object.assign({}, this.objectValidator[this.id], this.validator)
      }
    }

    return this.validator
  }

  get compLabel() {
    return this.translate ? this.$t(this.label) : this.label
  }
}
