import Vue from 'vue'

import VueApollo from "vue-apollo";
import {InMemoryCache} from "apollo-cache-inmemory";
import {ApolloLink, concat, from} from "apollo-link";
import ApolloClient from "apollo-client";
import {HttpLink} from "apollo-link-http";
import { onError } from 'apollo-link-error'
import router from "@/router";


const AUTHORIZATION_HEADER = 'Authorization'
export const ACCESS_CODE = 'AccessCode'

const httpLink = new HttpLink({ uri: '/api/graphql' })

const authMiddleware = new ApolloLink((operation, forward) => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        //@ts-ignore
        get: (searchParams, prop) => searchParams.get(prop),
    });
    // @ts-ignore
    const accessCode = params.accessCode
    const authorization = sessionStorage.getItem(AUTHORIZATION_HEADER) || localStorage.getItem(AUTHORIZATION_HEADER)
    // Add authorization to the headers if available
    if (accessCode) {
        operation.setContext({
            headers: {
                authorization: `AccessCode ${accessCode}`,
            }
        })
    } else if (authorization) {
        operation.setContext({
            headers: {
                authorization: authorization,
            }
        })
    }
    return forward(operation);
})

const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
        // This actually exists on networkError
        // @ts-ignore
        if (networkError?.statusCode === 401) {
            localStorage.removeItem(AUTHORIZATION_HEADER)
            sessionStorage.removeItem(AUTHORIZATION_HEADER)
            router.push({ name: 'login' })
        }
    }
})

export const apolloClient = new ApolloClient({
    link: from([authMiddleware, errorMiddleware, httpLink]),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: { query: { fetchPolicy: 'no-cache'} },
    connectToDevTools: true,
})

export const apolloProvider = new VueApollo({
    defaultClient: apolloClient
})

// @ts-ignore
Vue.use(VueApollo)
