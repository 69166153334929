import Vue from 'vue'

Vue.filter('currency', (value: string) => {
  return !value ? '' : value.replace('EUR', '€').replace('DOL', '$')
})

Vue.filter('unit', (value: string) => {
  return !value
    ? ''
    : value
        .replace(new RegExp('KGM', 'g'), 'kg')
        .replace(new RegExp('LBR', 'g'), 'lbs')
        .replace(new RegExp('CM', 'g'), 'cm')
        .replace(new RegExp('KG', 'g'), 'kg')
})

const getDate = (date: Date, format: Intl.DateTimeFormatOptions) => {
  if (!date || !date.getTime()) return ''
  return date.toLocaleString('en-GB', format)
}

Vue.filter('date', (date: number | string) => {
    let parsedDate
    // Check if value is a string and doesn't include time, if so we don't have to parse it but just return the value
    if (isNaN(<number>date) && isNaN(new Date(date).getMilliseconds())) {
        parsedDate = date
    } else {
        let createdDate
        // If it isn't a number, do nothing but creating a date
        if ((isNaN(<number>date))){
            createdDate = new Date(date)
        // If it is a number; make sure its a number and not a string of numbers by pre-fixing a '+'
        } else {
            createdDate = new Date(+date)
        }
        
        parsedDate = getDate(createdDate, {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        })
    }
    return (parsedDate)
})

Vue.filter('time', (date: number) => {
  return getDate(new Date(date), {
    hour: '2-digit',
    minute: '2-digit'
  })
})

Vue.filter('dateAndTime', (date: number, time: number) => {
  let format = time
    ? {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
    : {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }
  return getDate(new Date(time ? time : date), format)
})

Vue.filter('dateNoTime', (date: any) => {
  if (!isNaN(Number(date))) {
    return getDate(new Date(date as number), { day: '2-digit', month: 'short', year: 'numeric' })
  } else return date
})

Vue.filter('balance', (balance: number) => balance / 1000000000000000000)

Vue.filter('numberToDate', (date: number) => {
  if (date) {
    return new Date(date).toString()
  } else return null
})

Vue.filter('ecmr', (value: string) => {
    // Match all occurences of eCMR case insensitive and replace for e-CMR
    return value?.replaceAll(/ecmr/ig, "e-CMR")
})

Vue.filter('smallDateTime', (date: string) => {
    let formattedDate: TypeValue[] = new Intl.DateTimeFormat('nl-NL', {
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).formatToParts(new Date(date))
    const time = `${formattedDate.find(test => test.type === 'hour')!!.value}:${formattedDate.find(test => test.type === 'minute')!!.value}`
    return `${formattedDate.find(test => test.type === 'day')!!.value}-${formattedDate.find(test => test.type === 'month')!!.value}${time === "00:00" ? "" : ` ${time}` }`
})

interface TypeValue {
    type: string
    value: string
}
