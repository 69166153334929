module.exports = {
  "language": "Keel",
  "actors": "Näitlejad",
  "ID": "Isikutunnistus",
  "search": "Otsing",
  "copied": "Kopeeritud lõikelauale",
  "sort": "Sorteeri {0}",
  "yes": "Jah",
  "no": "Mitte",
  "notifications": {
    "title": "Teatised",
    "empty": "Teil pole märguandeid"
  },
  "auth": {
    "logout": "Logi välja",
    "login": "Logi sisse",
    "register": "Registreeri uus konto"
  },
  "user": {
    "changePassword": "Muuda salasõna",
    "firstPasswordChange": " izmantojot pirmo pieteikšanos\"",
    "plural": "Kasutajad",
    "single": "Kasutaja",
    "filters": {
      "locked": "Näita lukustatud kasutajaid"
    },
    "fields": {
      "password": "Parool",
      "authorizations": "Volitused",
      "email": "E -kiri",
      "name": "Nimetus",
      "repeatPassword": "Korda salasõna"
    },
    "validation": {
      "length": "8 tähemärki pikk",
      "uppercase": "Üks suur täht",
      "lowercase": "Üks väiketäht",
      "number": "Üks number",
      "special": "Üks eriline märk (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Telefon",
      "company": "Ettevõtte nimi",
      "street": "Tänav",
      "zipCode": "Postiindeks",
      "city": "Linn",
      "country": "Riik",
      "houseNumber": "Maja number"
    }
  },
  "errors": {
    "loginWrong": "\"Brukernavn og passord stemmer ikke overens. Vær så snill",
    "notFound": {
      "title": "lehte ei leitud",
      "message": "Vabandust! Seda lehte ei leitud."
    },
    "noInternet": "Вы приняли груз без активного подключения к Интернету. \\ Nplease Проверьте свои настройки WiFi или мобильных данных. \\ NONCE подключение восстанавливается"
  },
  "messages": {
    "sureDelete": "Är du säker på att du vill ta bort {0}?",
    "sureSave": "Är du säker på att du vill spara {0}?"
  },
  "tradelane": {
    "fields": {
      "sender": "Saatja",
      "receiver": "Vastuvõtja"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Numbrimärk"
    }
  },
  "company": {
    "single": "Ettevõte"
  },
  "scan": {
    "currentStage": "Praegune etapp",
    "description": "Kirjeldus",
    "qrCode": "QR kood",
    "locationNotAccessible": "Asukohta pole leitud",
    "noEmballage": "Pakendit pole",
    "noMessage": "Sõnumit pole lisatud",
    "missingCargo": "Puuduv last",
    "shipmentAndBarcodes": "Saadetis ja vöötkoodid",
    "scanPackages": "Skannimispaketid",
    "emballage": "Hammustus",
    "required": "Nõutud",
    "upload": "Laadi üles",
    "statusRequirements": "Olekunõuded",
    "addPhoto": "Lisage foto",
    "addAttachments": "Lisa manused",
    "addMessage": "Lisateade lisage",
    "addEmballage": "Lisage embalage",
    "signOnGlass": "Klaasile logima",
    "start": "Alustama",
    "shelvesExtensions": "Riiulid ja pikendused",
    "notready": "Ei ole valmis",
    "manco": "Puudulik",
    "fields": {
      "message": "Teade",
      "attach": "Ühendama",
      "detach": "Lahti ühendama"
    },
    "changeAmounts": "Muutke summasid",
    "damage": "Kahjustus",
    "not": "Mitte",
    "mancoMessage": "Vai nevarētu skenēt svītrkodu vai paketi? Ziņot par to šajā jomā ..."
  },
  "sign": {
    "sign": "Märk",
    "signAbsence": "Allkirjastatud kliendi puudumisel"
  },
  "erp": {
    "single": "ERP",
    "packaging": "Pakend"
  },
  "nav": {
    "finished": "Valmis",
    "current": "Vooluhulk",
    "overview": "Ülevaade",
    "messages": "Sõnumid",
    "shipment": "Saadetis",
    "shipments": "Saadetis",
    "settings": "Sätted",
    "planning": "Joblist",
    "report": "Aruanded"
  },
  "wallet": {
    "single": "Rahakott"
  },
  "common": {
    "info": "Teave",
    "status": "Olek",
    "location": "Asukoht",
    "contact": "Kontakt",
    "contacts": "Kontaktid",
    "photos": "Fotod",
    "attachments": "Manused",
    "identifier": "Identifikaator",
    "type": "Tüüp",
    "actions": "Toimingud",
    "name": "Nimetus",
    "value": "Väärtustama",
    "tradelane": "Kaubavahetus",
    "date": "Kuupäev",
    "actor": "Näitleja",
    "create": "Looma",
    "from": "-Lt",
    "to": "Juurde",
    "general": "Üld-",
    "back": "tagasi",
    "next": "Järgmine",
    "finish": "Lõpetama",
    "clear": "Selge",
    "notes": "Märkused",
    "details": "Üksikasjad",
    "retry": "Uuesti proovima",
    "amount": "Summa",
    "trip": "Reis",
    "references": "Viited",
    "in": "Sees",
    "out": "Väljas",
    "senderMessage": "Saatja märkus",
    "receiverMessage": "Vastuvõtja märkus",
    "total": "Kogusumma"
  },
  "actions": {
    "shipmentCreated": "Loodud saadetis",
    "shipmentUpdated": "Saadetis värskendatud",
    "planningCreated": "Planeerimine on loodud",
    "planningUpdated": "Planeeritud ajakohastatud",
    "accesscodeCreated": "Loodud juurdepääsukood",
    "accesscodeShared": "Juurdepääsukood jagatud",
    "notificationSent": "Teade saadetud",
    "partOfAccesscodeCreated": "Loodud juurdepääsukoodi element planeerimiseks",
    "partOfAccesscodeShared": "Jagatud juurdepääsukoodi element planeerimiseks",
    "addObj": "Lisage {0}",
    "add": "Lisama",
    "save": "Kokkuhoid",
    "saveObj": "Salvesta {0}",
    "copy": "Koopia",
    "send": "Saada",
    "continue": "Jätkuma",
    "close": "Sulge"
  },
  "shipment": {
    "eta": "Eta",
    "ata": "Ata",
    "single": "Saadetis",
    "status": "Olek",
    "finished": "Lõpetatud saadetised",
    "pickup": "Korja üles",
    "delivery": "Tarnimine",
    "delivered": "Tarnimine",
    "pickedUp": "Kohale toimetatud",
    "fields": {
      "parties": "Osapooled",
      "createdAt": "Loodud kell",
      "updatedAt": "Uuendatud kell"
    },
    "consignment": {
      "single": "\"Partii",
      "scanned": {
        "waiting": "\"{Id} Tallennettu! \\ NSTORTOR -tiedot voivat viedä jonkin aikaa. \\ NY"
      }
    },
    "containers": {
      "fields": {
        "hsCode": "HS -kood",
        "packQuantity": "Pakkkogus",
        "packSize": "Pakisuurus",
        "weight": "Kaal"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Sisaldab ohtlikke kaupu"
      }
    },
    "documents": {
      "fields": {
        "file": "Toimik"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Kaubahind"
      }
    },
    "lastStatus": "Viimane olek"
  },
  "accept": {
    "updateShipment": "Värskendussaadetis",
    "zeroEmballageMessage": "Du har ikke spesifisert noen emballasje. Vennligst spesifiser en grunn nedenfor eller klikk Fullfør."
  },
  "apiKeys": {
    "plural": "API võtmed",
    "single": "API -võti",
    "fields": {
      "key": "Võti"
    }
  },
  "planning": {
    "driverInformation": "Juhi teave"
  },
  "from": "-Lt",
  "for": "Jaoks",
  "commissioned_by": "Kohustatud",
  "planning_finished": "Planeerimine valmis",
  "sure_question": "Oled sa kindel?",
  "sure_question_cancel": "Tühistate aktiivse peatuse. Oled sa kindel?"
}