























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Search from '@/general/components/common/Search.vue'
import { Order, Pagination, Sort } from '@/generated/globalTypes'

@Component({
  components: {
    Search
  }
})
export default class BaseDataTable extends Vue {
  @Prop({ required: false, default: null }) id: number | null

  @Prop({ default: null }) service: ((entityID: number | null, pagination: Pagination) => Promise<PaginationResult>) | null
  @Prop({ default: false, type: Boolean}) search: boolean
  @Prop({ default: false, type: Boolean}) hidePagination: boolean
  @Prop({ required: false }) nested: string
  @Prop({ required: false }) setData: (any)
  @Prop({ required: false }) fullData: (any)
  @Prop({ default: () => {}}) args: any
  @Prop() items: any[]

  page: number = 1
  searchString: string | null = null
  sort: Sort | null = null

  displayItems: PaginationResult = { items: [], total: 0 }

  options: any = {}

  get showPagination(): boolean {
    return !!(this.service && this.displayItems.items.length)
  }

  get pagination() : Pagination {
    return { page: this.page - 1, itemsPerPage: 20, search: this.searchString, sort: this.sort }
  }

  get amountOfPages() {
    return Math.ceil(this.displayItems.total / this.pagination.itemsPerPage)
  }

  mounted() {
    this.getAll()
  }

  @Watch('items', { deep: true })
  updateItems() {
    this.getAll()
  }

  @Watch('options', { deep: true })
  setSort(){
    if (this.showPagination && this.options.sortBy[0]) {
      this.sort = {
        by: this.options.sortBy[0],
        order: this.options.sortDesc[0] ? Order.DESC : Order.ASC
      }
      this.getAll()
    }
  }

  doSearch(search: string) {
    this.searchString = search
    this.getAll()
  }

  getAll() {
    if (this.service) {
      this.service(this.id, this.pagination).then(result => {
        this.displayItems = result
      })
    } else {
      this.getItems()
    }
  }

  getItems() {
    const itms = this.items ? this.items : this.displayItems.items
    let items = []
    items.push(...itms)
    if (this.showPagination && this.amountOfPages > 1) {
      const amount = this.pagination.itemsPerPage - (itms.length % this.pagination.itemsPerPage)
      for (let i = 0; i < amount; i++) {
        items.push(null)
      }
    }
    this.displayItems = { items: items, total: itms.length }
  }
}

interface PaginationResult {
  items: any[]
  total: number
}
