import { State as RootState } from './index'
import { Module } from 'vuex'

const MAX_SAVED_CONSIGNMENTS = 8
const LOCAL_NAME = 'consignments'

interface Consignment {
  expeditor: string
  shipment: string
  consignment: string
  date: Date
}

class State {
  consignments: Consignment[] = []
}

const store: Module<State, RootState> = {
  state: new State(),
  namespaced: true,
  mutations: {
    set(state, consignments: Consignment[]) {
      state.consignments = consignments
    },
    add(state, consignment: Consignment) {
      if (
        !state.consignments.find(
          (el: any) => el.consignment === consignment.consignment && el.shipment === consignment.shipment && el.expeditor === consignment.expeditor
        )
      ) {
        consignment.date = new Date()
        state.consignments.push(consignment)

        if (state.consignments.length > MAX_SAVED_CONSIGNMENTS) {
          state.consignments.shift()
        }

        localStorage.setItem(LOCAL_NAME, JSON.stringify(state.consignments))
      }
    },
    remove(state, consignment: Consignment) {
      const c = state.consignments.findIndex(
        (el: any) =>
          el &&
          consignment &&
          el.consignment === consignment.consignment &&
          el.shipment === consignment.shipment &&
          el.expeditor === consignment.expeditor
      )
      if (c != -1) state.consignments.splice(c, 1)
      localStorage.setItem(LOCAL_NAME, JSON.stringify(state.consignments))
    },
    clear(state) {
      state.consignments = []
      localStorage.removeItem(LOCAL_NAME)
    }
  },
  actions: {
    init({ commit }) {
      const consignments = localStorage.getItem(LOCAL_NAME)

      if (consignments) {
        commit('set', JSON.parse(consignments))
      }
    }
  }
}

export default store
