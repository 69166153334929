module.exports = {
  "language": "Jazyk",
  "actors": "Herci",
  "ID": "Id",
  "search": "Vyhledávání",
  "copied": "Zkopírováno do schránky",
  "sort": "Třídit {0}",
  "yes": "Ano",
  "no": "Ne",
  "notifications": {
    "title": "Oznámení",
    "empty": "Nemáte žádná oznámení"
  },
  "auth": {
    "logout": "Odhlásit se",
    "login": "Přihlásit se",
    "register": "Zaregistrujte nový účet"
  },
  "user": {
    "changePassword": "Změnit heslo",
    "firstPasswordChange": "Reikalaujama pakeisti savo slaptažodį pirmajam prisijungimui",
    "plural": "Uživatelé",
    "single": "Uživatel",
    "filters": {
      "locked": "Zobrazit uzamčené uživatele"
    },
    "fields": {
      "password": "Heslo",
      "authorizations": "Oprávnění",
      "email": "E-mailem",
      "name": "název",
      "repeatPassword": "Zopakovat heslo"
    },
    "validation": {
      "length": "8 znaků dlouhé",
      "uppercase": "Jedno velké písmeno",
      "lowercase": "Jeden malý dopis",
      "number": "Jedno číslo",
      "special": "Jeden speciální znak (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Telefon",
      "company": "Jméno společnosti",
      "street": "ulice",
      "zipCode": "PSČ",
      "city": "Město",
      "country": "Země",
      "houseNumber": "číslo domu"
    }
  },
  "errors": {
    "loginWrong": "Käyttäjätunnus ja salasana eivät vastaa. Yritä uudelleen!",
    "notFound": {
      "title": "Stránka nenalezena",
      "message": "Jejda! Tato stránka nebyla nalezena."
    },
    "noInternet": "Du godtok lasten uten en aktiv internettforbindelse."
  },
  "messages": {
    "sureDelete": "Haluatko varmasti poistaa {0}?",
    "sureSave": "Haluatko varmasti tallentaa {0}?"
  },
  "tradelane": {
    "fields": {
      "sender": "Odesílatel",
      "receiver": "Přijímač"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Poznávací značka"
    }
  },
  "company": {
    "single": "Společnost"
  },
  "scan": {
    "currentStage": "Aktuální fáze",
    "description": "Popis",
    "qrCode": "QR kód",
    "locationNotAccessible": "Umístění nebylo nalezeno",
    "noEmballage": "Žádné obaly",
    "noMessage": "Žádná zpráva",
    "missingCargo": "Chybějící náklad",
    "shipmentAndBarcodes": "Zásilka a čárové kódy",
    "scanPackages": "Skenovací balíčky",
    "emballage": "Elom",
    "required": "Požadované",
    "upload": "nahrát",
    "statusRequirements": "Požadavky na stav",
    "addPhoto": "Přidat fotku",
    "addAttachments": "Přidejte přílohy",
    "addMessage": "Přidat zprávu",
    "addEmballage": "Přidejte elob",
    "signOnGlass": "Podepsat sklo",
    "start": "Start",
    "shelvesExtensions": "Police a rozšíření",
    "notready": "Není připraven",
    "manco": "Chybějící",
    "fields": {
      "message": "Zpráva",
      "attach": "Připojit",
      "detach": "Odpojit"
    },
    "changeAmounts": "Změnit částky",
    "damage": "Poškození",
    "not": "Ne",
    "mancoMessage": "Ar negalite nuskaityti brūkšninio kodo ar paketo? Pranešti apie tai šioje srityje ..."
  },
  "sign": {
    "sign": "Podepsat",
    "signAbsence": " jei nėra kliento\""
  },
  "erp": {
    "single": "ERP",
    "packaging": "Obal"
  },
  "nav": {
    "finished": "Hotové",
    "current": "Proud",
    "overview": "Přehled",
    "messages": "Zprávy",
    "shipment": "náklad",
    "shipments": "Zásilky",
    "settings": "Nastavení",
    "planning": "Jolist",
    "report": "Zprávy"
  },
  "wallet": {
    "single": "Peněženka"
  },
  "common": {
    "info": "Informace",
    "status": "Postavení",
    "location": "Umístění",
    "contact": "Kontakt",
    "contacts": "Kontakty",
    "photos": "Fotky",
    "attachments": "Přílohy",
    "identifier": "Identifikátor",
    "type": "Typ",
    "actions": "Akce",
    "name": "název",
    "value": "Hodnota",
    "tradelane": "TradeLane",
    "date": "datum",
    "actor": "Herec",
    "create": "Vytvořit",
    "from": "Z",
    "to": "Na",
    "general": "Všeobecné",
    "back": "Zadní",
    "next": "další",
    "finish": "Dokončit",
    "clear": "Průhledná",
    "notes": "Poznámky",
    "details": "Podrobnosti",
    "retry": "Opakovat",
    "amount": "Množství",
    "trip": "Výlet",
    "references": "Reference",
    "in": "v",
    "out": "Ven",
    "senderMessage": "Poznámka odesílatele",
    "receiverMessage": "Poznámka přijímače",
    "total": "Celkový"
  },
  "actions": {
    "shipmentCreated": "Zásilka vytvořená",
    "shipmentUpdated": "Aktualizována zásilka",
    "planningCreated": "Vytvořeno plánování",
    "planningUpdated": "Aktualizováno plánování",
    "accesscodeCreated": "Vytvořený přístupový kód",
    "accesscodeShared": "Přístupový kód sdílený",
    "notificationSent": "Oznámení odesláno",
    "partOfAccesscodeCreated": "Prvek vytvořeného přístupového kódu pro plánování",
    "partOfAccesscodeShared": "Prvek sdíleného přístupového kódu pro plánování",
    "addObj": "Přidat {0}",
    "add": "Přidat",
    "save": "Uložit",
    "saveObj": "Uložit {0}",
    "copy": "kopírovat",
    "send": "Poslat",
    "continue": "Pokračovat",
    "close": "Zavřít"
  },
  "shipment": {
    "eta": "ETA",
    "ata": "Ata",
    "single": "náklad",
    "status": "Postavení",
    "finished": "Finalizované zásilky",
    "pickup": "Vyzvednout",
    "delivery": "dodávka",
    "delivered": "dodávka",
    "pickedUp": "Doručené",
    "fields": {
      "parties": "Strany",
      "createdAt": "Vytvořeno na",
      "updatedAt": "Aktualizováno na"
    },
    "consignment": {
      "single": "Siunta",
      "scanned": {
        "waiting": "{id} saglabāti!"
      }
    },
    "containers": {
      "fields": {
        "hsCode": "HS kód",
        "packQuantity": "Množství balení",
        "packSize": "Velikost balení",
        "weight": "Hmotnost"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Obsahuje nebezpečné zboží"
      }
    },
    "documents": {
      "fields": {
        "file": "Soubor"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Cena nákladní dopravy"
      }
    },
    "lastStatus": "Poslední stav"
  },
  "accept": {
    "updateShipment": "Aktualizovat zásilku",
    "zeroEmballageMessage": "\"Jūs neesat norādījis nevienu iepakojumu. Lūdzu"
  },
  "apiKeys": {
    "plural": "Klávesy API",
    "single": "Klíč API",
    "fields": {
      "key": "Klíč"
    }
  },
  "planning": {
    "driverInformation": "Informace o řidiči"
  },
  "from": "Z",
  "for": "Pro",
  "commissioned_by": "Commisiosed by",
  "planning_finished": "Plánování dokončeno",
  "sure_question": "Jsi si jistá?",
  "sure_question_cancel": "Zrušíte aktivní zastávku. Jsi si jistá?"
}