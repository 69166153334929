import { EventEmitter } from 'events'

export class Websocket {
  eventEmitter: EventEmitter = new EventEmitter()

  websocket = new WebSocket(
    (location.protocol === 'http:' ? 'ws' : 'wss') + '://' + location.hostname + ((location.hostname === "localhost" || location.hostname === "127.0.0.1") ? ':8080' : '') + '/websocket'
  )

  connect() {
    const that = this
    this.websocket.onmessage = function(event) {
      let triple = JSON.parse(event.data)
      if (triple.first === "STATUS") {
        triple.first = 'SHIPMENT'
      }
      switch (triple.first) {
        case "SHIPMENT": {
          that.eventEmitter.emit('SHIPMENT', Number(triple.second))
          break;
        }
        case "PLANNING": {
          // triple.second.first is planning ID, triple.second.second is (nullable) attached user email
          that.eventEmitter.emit('PLANNING', Number(triple.second.first), triple.second.second)
          break;
        }
      }

      that.eventEmitter.emit('news', triple)
    }
    this.websocket.onclose = function(event) {
      setTimeout(function() {
        that.connect();
      }, 1000);
    }
    this.websocket.onerror = function(event) {
      that.websocket.close()
    }
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $websocket: Websocket
  }
}
