import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { getColor } from '@/plugins/utils'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    themes: {
      light: {
        primary: getColor('primary-color'),
        secondary: getColor('primary-color')
      }
    },
    options: {
      customProperties: true
    }
  }
})
