import { Websocket } from '@/plugins/websocket'
import { Store } from 'vuex'
import {State} from "@/stores";

export default function UpdatePlanningListener(socket: Websocket, navigator: any, state: State) {
  return (store: Store<any>) => {
    socket.eventEmitter.on('PLANNING', (id: number, email: string | null) => {
      store.dispatch('transport/planningUpdatedFromWebsocket', { id: id, email: email, currentUser: state.user?.email })
    })
    navigator.serviceWorker.addEventListener('message', (event: any) => {
      store.dispatch('transport/planningUpdatedFromWebsocket', { id: event.data.id, email: store.state.user?.email, currentUser: store.state.user?.email })
    });
  }
}