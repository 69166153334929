module.exports = {
  "language": "Lingua",
  "actors": "Attori",
  "ID": "ID",
  "search": "Ricerca",
  "copied": "Copiato negli appunti",
  "sort": "Ordina {0}",
  "yes": "sì",
  "no": "No",
  "notifications": {
    "title": "Notifiche",
    "empty": "Non hai notifiche"
  },
  "auth": {
    "logout": "Disconnettersi",
    "login": "Login",
    "register": "Registra nuovo account"
  },
  "user": {
    "changePassword": "Cambia la password",
    "firstPasswordChange": "È necessario modificare la password al primo accesso",
    "plural": "Utenti",
    "single": "Utente",
    "filters": {
      "locked": "Mostra utenti bloccati"
    },
    "fields": {
      "password": "Parola d'ordine",
      "authorizations": "Autorizzazioni",
      "email": "E-mail",
      "name": "Nome",
      "repeatPassword": "Ripeti la password"
    },
    "validation": {
      "length": "8 caratteri lunghi",
      "uppercase": "Una lettera maiuscola",
      "lowercase": "Una lettera minuscola",
      "number": "Un numero",
      "special": "Un personaggio speciale (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Telefono",
      "company": "Nome della ditta",
      "street": "Strada",
      "zipCode": "Cap",
      "city": "Città",
      "country": "Paese",
      "houseNumber": "numero di casa"
    }
  },
  "errors": {
    "loginWrong": "\"Usuário e senha não correspondem. Por favor",
    "notFound": {
      "title": "pagina non trovata",
      "message": "Oops! Questa pagina non è stata trovata."
    },
    "noInternet": "\"Przyjąłeś ładunek bez aktywnego połączenia internetowego. \\ Nplease Sprawdź swoje Wi -Fi lub ustawienia danych mobilnych. \\ Nonce Połączenie zostanie ponownie ustanawiane"
  },
  "messages": {
    "sureDelete": "Czy na pewno chcesz usunąć {0}?",
    "sureSave": "Czy na pewno chcesz zapisać {0}?"
  },
  "tradelane": {
    "fields": {
      "sender": "Mittente",
      "receiver": "Ricevitore"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Targa"
    }
  },
  "company": {
    "single": "Azienda"
  },
  "scan": {
    "currentStage": "Stage attuale",
    "description": "Descrizione",
    "qrCode": "QR Code",
    "locationNotAccessible": "Posizione non trovata",
    "noEmballage": "Nessun imballaggio",
    "noMessage": "Nessun messaggio aggiunto",
    "missingCargo": "Cargo mancante",
    "shipmentAndBarcodes": "Spedizione e codici a barre",
    "scanPackages": "Pacchetti di scansione",
    "emballage": "Imballaggio",
    "required": "Necessario",
    "upload": "Caricamento",
    "statusRequirements": "Requisiti di stato",
    "addPhoto": "Aggiungi foto",
    "addAttachments": "Aggiungi allegati",
    "addMessage": "Aggiungi un messaggio",
    "addEmballage": "Aggiungi emballaggio",
    "signOnGlass": "Firma su vetro",
    "start": "Inizio",
    "shelvesExtensions": "Scaffali ed estensioni",
    "notready": "Non pronto",
    "manco": "Mancante",
    "fields": {
      "message": "Messaggio",
      "attach": "Collegare",
      "detach": "Disconnessione"
    },
    "changeAmounts": "Importi di cambiamento",
    "damage": "Danno",
    "not": "Non",
    "mancoMessage": "Non è stato possibile scansionare il codice a barre o il pacchetto non presente? Segnala in questo campo ..."
  },
  "sign": {
    "sign": "Cartello",
    "signAbsence": "Firmato in assenza del cliente"
  },
  "erp": {
    "single": "ERP",
    "packaging": "Confezione"
  },
  "nav": {
    "finished": "Finito",
    "current": "Attuale",
    "overview": "Panoramica",
    "messages": "Messaggi",
    "shipment": "Spedizione",
    "shipments": "Spedizioni",
    "settings": "Impostazioni",
    "planning": "Joblist",
    "report": "Rapporti"
  },
  "wallet": {
    "single": "Portafoglio"
  },
  "common": {
    "info": "Informazioni",
    "status": "Stato",
    "location": "Posizione",
    "contact": "Contatto",
    "contacts": "Contatti",
    "photos": "Fotografie",
    "attachments": "Allegati",
    "identifier": "Identificatore",
    "type": "Tipo",
    "actions": "Azioni",
    "name": "Nome",
    "value": "Valore",
    "tradelane": "Tradelane",
    "date": "Data",
    "actor": "Attore",
    "create": "Creare",
    "from": "Da",
    "to": "Per",
    "general": "Generale",
    "back": "Di ritorno",
    "next": "Prossimo",
    "finish": "Fine",
    "clear": "Chiaro",
    "notes": "Appunti",
    "details": "Particolari",
    "retry": "Riprovare",
    "amount": "Quantità",
    "trip": "Viaggio",
    "references": "Riferimenti",
    "in": "In",
    "out": "Fuori",
    "senderMessage": "Nota mittente",
    "receiverMessage": "Nota del ricevitore",
    "total": "Totale"
  },
  "actions": {
    "shipmentCreated": "spedizione creata",
    "shipmentUpdated": "Spedizione aggiornata",
    "planningCreated": "Pianificazione creata",
    "planningUpdated": "Pianificazione aggiornata",
    "accesscodeCreated": "Codice di accesso creato",
    "accesscodeShared": "Codice di accesso condiviso",
    "notificationSent": "Notifica inviata",
    "partOfAccesscodeCreated": "Elemento del codice di accesso creato per la pianificazione",
    "partOfAccesscodeShared": "Elemento del codice di accesso condiviso per la pianificazione",
    "addObj": "Aggiungi {0}",
    "add": "Aggiungere",
    "save": "Salva",
    "saveObj": "Salva {0}",
    "copy": "copia",
    "send": "Inviare",
    "continue": "Continua",
    "close": "Chiudere"
  },
  "shipment": {
    "eta": "Eta",
    "ata": "Ata",
    "single": "Spedizione",
    "status": "Stato",
    "finished": "Spedizioni finalizzate",
    "pickup": "Raccogliere",
    "delivery": "Consegna",
    "delivered": "Consegna",
    "pickedUp": "Consegnato",
    "fields": {
      "parties": "Parti",
      "createdAt": "Creato a",
      "updatedAt": "Aggiornato a"
    },
    "consignment": {
      "single": "Spedizione",
      "scanned": {
        "waiting": "{id} guardados! \\ nstoring Los datos pueden llevar algún tiempo."
      }
    },
    "containers": {
      "fields": {
        "hsCode": "Codice HS",
        "packQuantity": "Quantità di confezione",
        "packSize": "Dimensione del pacchetto",
        "weight": "Il peso"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Contiene merci pericolose"
      }
    },
    "documents": {
      "fields": {
        "file": "File"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Prezzo di trasporto"
      }
    },
    "lastStatus": "Ultimo status"
  },
  "accept": {
    "updateShipment": "Aggiorna la spedizione",
    "zeroEmballageMessage": "No ha especificado ningún embalaje. Especifique un motivo a continuación o haga clic en Finalizar."
  },
  "apiKeys": {
    "plural": "Tasti API",
    "single": "Chiave API",
    "fields": {
      "key": "Chiave"
    }
  },
  "planning": {
    "driverInformation": "Informazioni sul driver"
  },
  "from": "Da",
  "for": "Per",
  "commissioned_by": "Commissionato da",
  "planning_finished": "Pianificazione finita",
  "sure_question": "Sei sicuro?",
  "sure_question_cancel": "Stai cancellando una fermata attiva. Sei sicuro?"
}