module.exports = {
  "language": "Taal",
  "actors": "Actoren",
  "ID": "ID",
  "search": "Zoek",
  "copied": "Gekopieerd naar klembord",
  "sort": "Sorteer {0}",
  "yes": "Ja",
  "no": "Nee",
  "notifications": {
    "title": "Notificaties",
    "empty": "Je hebt geen notificaties"
  },
  "auth": {
    "logout": "Log uit",
    "login": "Login",
    "register": "Registreer nieuw account"
  },
  "user": {
    "changePassword": "Verander wachtwoord",
    "firstPasswordChange": "Je bent verplicht om je wachtwoord te veranderen na de eerste keer inloggen",
    "plural": "Gebruikers",
    "single": "Gebruiker",
    "filters": {
      "locked": "Toon vergrendelde gebruikers"
    },
    "fields": {
      "password": "Wachtwoord",
      "authorizations": "Bevoegdheden",
      "email": "Email",
      "name": "Naam",
      "repeatPassword": "Herhaal wachtwoord"
    },
    "validation": {
      "length": "8 Karakters lang",
      "uppercase": "Een hoofdletter",
      "lowercase": "Een kleine letter",
      "number": "Een nummer",
      "special": "Een speciaal karakter (!@#$%^&*_=+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Telefoon",
      "company": "Bedrijfsnaam",
      "street": "Straat",
      "zipCode": "Postcode",
      "city": "Stad",
      "country": "Land",
      "houseNumber": "Huisnummer"
    }
  },
  "errors": {
    "loginWrong": "Gebruikersnaam en wachtwoord komen niet overeen.\\nProbeer het opnieuw!",
    "notFound": {
      "title": "Pagina niet gevonden",
      "message": "Oeps! Deze pagina is niet gevonden."
    },
    "noInternet": "\"Vous avez accepté la cargaison sans connexion Internet active.\\nVérifiez vos paramètres WiFi ou de données mobiles\\nLorsque la connexion est rétablie"
  },
  "messages": {
    "sureDelete": "Weet je zeker dat je {0} wilt verwijderen?",
    "sureSave": "Weet je zeker dat je {0} wilt opslaan?"
  },
  "tradelane": {
    "fields": {
      "sender": "Verzender",
      "receiver": "Ontvanger"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Kenteken"
    }
  },
  "company": {
    "single": "Bedrijf"
  },
  "scan": {
    "currentStage": "Huidig stadium",
    "description": "Omschrijving",
    "qrCode": "QR Code",
    "locationNotAccessible": "Locatie niet gevonden",
    "noEmballage": "Geen emballage",
    "noMessage": "Weiger",
    "missingCargo": "Manco",
    "shipmentAndBarcodes": "Verzending en barcodes",
    "scanPackages": "Scanpakketten",
    "emballage": "Emballage",
    "required": "Verplicht",
    "upload": "Uploaden",
    "statusRequirements": "Status vereisten",
    "addPhoto": "Foto toevoegen",
    "addAttachments": "Bijlagen toevoegen",
    "addMessage": "Bericht toevoegen",
    "addEmballage": "Voeg Emballage toe",
    "signOnGlass": "Handtekening toevoegen",
    "start": "Start",
    "shelvesExtensions": "Platen en Opzetters",
    "notready": "Niet klaar",
    "manco": "Manco",
    "fields": {
      "message": "Bericht",
      "attach": "Aankoppelen",
      "detach": "Afkoppelen"
    },
    "changeAmounts": "Aantallen  wijzigen",
    "damage": "Schade",
    "not": "Niet",
    "mancoMessage": "Is het niet gelukt om de streepjes code te scannen of is het pakketje afwezig? Meld het in dit veld..."
  },
  "sign": {
    "sign": "Onderteken",
    "signAbsence": "Getekend in afwezigheid van klant"
  },
  "erp": {
    "single": "ERP",
    "packaging": "Verpakking"
  },
  "nav": {
    "finished": "Afgerond",
    "current": "Huidige",
    "overview": "Overzicht",
    "messages": "Berichten",
    "shipment": "Zending",
    "shipments": "Zendingen",
    "settings": "Instellingen",
    "planning": "Ritten",
    "report": "Rapporten"
  },
  "wallet": {
    "single": "Portemonnee"
  },
  "common": {
    "info": "Info",
    "status": "Status",
    "location": "Location",
    "contact": "Contact",
    "contacts": "Contacten",
    "photos": "Foto's",
    "attachments": "Bijlagen",
    "identifier": "Identifier",
    "type": "Type",
    "actions": "Acties",
    "name": "Naam",
    "value": "Waarde",
    "tradelane": "Tradelane",
    "date": "Datum",
    "actor": "Actor",
    "create": "Maak aan",
    "from": "Van",
    "to": "Tot",
    "general": "Algemeen",
    "back": "Terug",
    "next": "Volgende",
    "finish": "Afronden",
    "clear": "Opnieuw",
    "notes": "Notities",
    "details": "Details",
    "retry": "Opnieuw",
    "amount": "Aantal",
    "trip": "Rit",
    "references": "Referenties",
    "in": "In",
    "out": "Uit",
    "senderMessage": "Opmerking afzender",
    "receiverMessage": "Opmerking ontvanger",
    "total": "Totaal"
  },
  "actions": {
    "shipmentCreated": "Zending Aangemaakt",
    "shipmentUpdated": "Zending Bijgewerkt",
    "planningCreated": "Planning aangemaakt",
    "planningUpdated": "Planning bijgewerkt",
    "accesscodeCreated": "Toegangscode aangemaakt",
    "accesscodeShared": "Toegangscode gedeeld",
    "notificationSent": "Notificatie verstuurd",
    "partOfAccesscodeCreated": "Onderdeel van aangemaakte toegangscode voor planning",
    "partOfAccesscodeShared": "Onderdeel van gedeelde toegangscode voor planning",
    "addObj": "Voeg {0} toe",
    "add": "Voeg toe",
    "save": "Opslaan",
    "saveObj": "Sla {0} op",
    "copy": "Kopieer",
    "send": "Verstuur",
    "continue": "Verder",
    "close": "Sluiten"
  },
  "shipment": {
    "eta": "ETA",
    "ata": "ATA",
    "single": "Zending",
    "status": "Status",
    "finished": "Afgeronde Zendingen",
    "pickup": "Ophalen",
    "delivery": "Afleveren",
    "delivered": "Afgeleverd",
    "pickedUp": "Opgehaald",
    "fields": {
      "parties": "Partijen",
      "createdAt": "Aangemaakt op",
      "updatedAt": "Bijgewerkt op"
    },
    "consignment": {
      "single": "Consignatie",
      "scanned": {
        "waiting": "{id} opgeslagen! Data opslaan kan even duren.\\nJe kan doorgaan met het scannen van de volgende lading."
      }
    },
    "containers": {
      "fields": {
        "hsCode": "HS Code",
        "packQuantity": "Aantal pakketten",
        "packSize": "Stuks per pakket",
        "weight": "Gewicht"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Bevat gevaarlijke goederen"
      }
    },
    "documents": {
      "fields": {
        "file": "Bestand"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Vrachtprijs"
      }
    },
    "lastStatus": "Laatste status"
  },
  "accept": {
    "updateShipment": "Bijwerken lading",
    "zeroEmballageMessage": "Je hebt geen emballage gespecificeerd. Voeg een reden toe of druk op afronden"
  },
  "apiKeys": {
    "plural": "API Keys",
    "single": "API Key",
    "fields": {
      "key": "Key"
    }
  },
  "planning": {
    "driverInformation": "Vervoerders info"
  },
  "from": "Van",
  "for": "Voor",
  "commissioned_by": "In opdracht van",
  "planning_finished": "Rit klaar",
  "sure_question": "Weet je het zeker?",
  "sure_question_cancel": "Je breekt nu de stop af. Weet je het zeker?"
}