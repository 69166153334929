import Vue from 'vue'

class Globals {
  title = 'CargoLedger'
  menuBreakpoint = 960
}

Vue.prototype.$globals = new Globals()

declare module 'vue/types/vue' {
  interface Vue {
    $globals: Globals
  }
}
