module.exports = {
  "language": "Linguagem",
  "actors": "Atores",
  "ID": "EU IRIA",
  "search": "Procurar",
  "copied": "Copiado para a área de transferência",
  "sort": "Classificar {0}",
  "yes": "Sim",
  "no": "Não",
  "notifications": {
    "title": "Notificações",
    "empty": "Você não tem notificações"
  },
  "auth": {
    "logout": "Sair",
    "login": "Conecte-se",
    "register": "Registrar nova conta"
  },
  "user": {
    "changePassword": "Mudar senha",
    "firstPasswordChange": "Você deve alterar sua senha no primeiro login",
    "plural": "Usuários",
    "single": "Do utilizador",
    "filters": {
      "locked": "Mostre usuários bloqueados"
    },
    "fields": {
      "password": "Senha",
      "authorizations": "Autorizações",
      "email": "E-mail",
      "name": "Nome",
      "repeatPassword": "Repita a senha"
    },
    "validation": {
      "length": "8 caracteres de comprimento",
      "uppercase": "Uma letra maiúscula",
      "lowercase": "Uma letra minúscula",
      "number": "Um número",
      "special": "Um personagem especial (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Telefone",
      "company": "Nome da empresa",
      "street": "Rua",
      "zipCode": "Código postal",
      "city": "Cidade",
      "country": "País",
      "houseNumber": "Número da casa"
    }
  },
  "errors": {
    "loginWrong": "Nombre de usuario y contraseña no coinciden. ¡Inténtalo de nuevo!",
    "notFound": {
      "title": "página não encontrada",
      "message": "Opa! Esta página não foi encontrada."
    },
    "noInternet": "Вие приехте товара без активна интернет връзка."
  },
  "messages": {
    "sureDelete": " че искате да изтриете {0}?\"",
    "sureSave": " че искате да запазите {0}?\""
  },
  "tradelane": {
    "fields": {
      "sender": "Remetente",
      "receiver": "Receptor"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Placa de carro"
    }
  },
  "company": {
    "single": "Companhia"
  },
  "scan": {
    "currentStage": "Estágio atual",
    "description": "Descrição",
    "qrCode": "Código QR",
    "locationNotAccessible": "Localização não encontrada",
    "noEmballage": "Sem embalagem",
    "noMessage": "Nenhuma mensagem adicionada",
    "missingCargo": "Cargo ausente",
    "shipmentAndBarcodes": "Remessa e códigos de barras",
    "scanPackages": "Pacotes de varredura",
    "emballage": "Embalagem",
    "required": "Requeridos",
    "upload": "Envio",
    "statusRequirements": "Requisitos de status",
    "addPhoto": "Adicione a foto",
    "addAttachments": "Adicionar Anexos",
    "addMessage": "Adicionar mensagem",
    "addEmballage": "Adicione a embalagem",
    "signOnGlass": "Assine no vidro",
    "start": "Começar",
    "shelvesExtensions": "Prateleiras e extensões",
    "notready": "Não está pronto",
    "manco": "Ausência de",
    "fields": {
      "message": "Mensagem",
      "attach": "Conectar",
      "detach": "desconectar"
    },
    "changeAmounts": "Mudanças de alteração",
    "damage": "Dano",
    "not": "Não",
    "mancoMessage": "Não poderia escanear o código de barras ou o pacote não presente? Relate neste campo ..."
  },
  "sign": {
    "sign": "Sinal",
    "signAbsence": "Assinado na ausência de cliente"
  },
  "erp": {
    "single": "Erp",
    "packaging": "Embalagem"
  },
  "nav": {
    "finished": "Finalizado",
    "current": "Atual",
    "overview": "Visão geral",
    "messages": "Mensagens",
    "shipment": "envio",
    "shipments": "Remessas",
    "settings": "Definições",
    "planning": "Lista de empregos",
    "report": "Relatórios"
  },
  "wallet": {
    "single": "Carteira"
  },
  "common": {
    "info": "Info",
    "status": "Status",
    "location": "Localização",
    "contact": "Contato",
    "contacts": "Contatos",
    "photos": "Fotos",
    "attachments": "Anexos",
    "identifier": "Identificador",
    "type": "Modelo",
    "actions": "Ações",
    "name": "Nome",
    "value": "Valor",
    "tradelane": "Faixa de Comércio",
    "date": "Encontro",
    "actor": "Ator",
    "create": "Crio",
    "from": "A partir de",
    "to": "Para",
    "general": "Em geral",
    "back": "De volta",
    "next": "Próximo",
    "finish": "Terminar",
    "clear": "Claro",
    "notes": "Notas",
    "details": "Detalhes",
    "retry": "Tente novamente",
    "amount": "Quantia",
    "trip": "Viagem",
    "references": "Referências",
    "in": "Dentro",
    "out": "Fora",
    "senderMessage": "Observação do remetente",
    "receiverMessage": "Observação do receptor",
    "total": "Total"
  },
  "actions": {
    "shipmentCreated": "Remessa criada",
    "shipmentUpdated": "Remessa atualizada",
    "planningCreated": "Planejamento criado",
    "planningUpdated": "Planejamento atualizado",
    "accesscodeCreated": "Código de acesso criado",
    "accesscodeShared": "Código de acesso compartilhado",
    "notificationSent": "Notificação enviada",
    "partOfAccesscodeCreated": "Elemento do código de acesso criado para planejamento",
    "partOfAccesscodeShared": "Elemento do código de acesso compartilhado para planejamento",
    "addObj": "Adicionar {0}",
    "add": "Adicionar",
    "save": "Salvar",
    "saveObj": "Salvar {0}",
    "copy": "cópia de",
    "send": "Mandar",
    "continue": "Continuar",
    "close": "Perto"
  },
  "shipment": {
    "eta": "ETA",
    "ata": "Ata",
    "single": "envio",
    "status": "Status",
    "finished": "Remessas finalizadas",
    "pickup": "Pegar",
    "delivery": "Entrega",
    "delivered": "Entrega",
    "pickedUp": "Entregue",
    "fields": {
      "parties": "Partidos",
      "createdAt": "Criado em",
      "updatedAt": "Atualizado em"
    },
    "consignment": {
      "single": "Consignação",
      "scanned": {
        "waiting": "{Id} Zapisane! \\ Dane mogą zająć trochę czasu. \\ nyou może kontynuować inną przesyłkę."
      }
    },
    "containers": {
      "fields": {
        "hsCode": "Código HS",
        "packQuantity": "Quantidade de embalagem",
        "packSize": "Tamanho do pacote",
        "weight": "Peso"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Contém bens perigosos"
      }
    },
    "documents": {
      "fields": {
        "file": "Arquivo"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Preço de frete"
      }
    },
    "lastStatus": "Último status"
  },
  "accept": {
    "updateShipment": "Atualizar remessa",
    "zeroEmballageMessage": "Nie określałeś żadnego opakowania. Podaj powód poniżej lub kliknij zakończ."
  },
  "apiKeys": {
    "plural": "Chaves da API",
    "single": "Chave API",
    "fields": {
      "key": "Chave"
    }
  },
  "planning": {
    "driverInformation": "Informações do driver"
  },
  "from": "A partir de",
  "for": "Por",
  "commissioned_by": "Comissionado por",
  "planning_finished": "Planejamento terminado",
  "sure_question": "Tem certeza?",
  "sure_question_cancel": "Você está cancelando uma parada ativa. Tem certeza?"
}