import Vue from 'vue'
import Vuex from 'vuex'
import jwtDecode from 'jwt-decode'
import { StoredUser } from '@/modules/users/models/User'
import snackbar from './snackbar'
import transport from './transport'
import scan from './scan'
import loading from './loading'

import UpdatePlanningListener from '@/plugins/UpdatePlanningListener'
import { Websocket } from '@/plugins/websocket'
import {GetAbout_GetAbout} from "@/generated/GetAbout";

Vue.use(Vuex)
// Init websocket here so it's available to pass initialized as lugin
Vue.prototype.$websocket = new Websocket() as Websocket

export interface State {
  user: StoredUser | undefined
  jwtToken: string | undefined
  firebaseToken: string | undefined
  zebra: boolean
  nativeMode: boolean
  barcode: string | undefined
  gps: string | undefined
  mobileTitle: MobileTitle | undefined
  onConsignment: boolean
  onStatusUpdate: false
  notFound: boolean
  showContent: boolean
  systemSettings: GetAbout_GetAbout[]
  hideCompletedStops: boolean
}

const state: State = {
  user: undefined,
  jwtToken: undefined,
  firebaseToken: undefined,
  zebra: false,
  nativeMode: false,
  barcode: undefined,
  gps: undefined,
  mobileTitle: undefined,
  onConsignment: false,
  onStatusUpdate: false,
  notFound: false,
  showContent: true,
  systemSettings: [] as GetAbout_GetAbout[],
  hideCompletedStops: localStorage.getItem("hideCompleteStops") === "true"
}

// @ts-ignore
window.barcode = (barcode: string) => {
  console.log(`Stored barcode: "${barcode}"`);
  state.barcode = barcode
}
// @ts-ignore
window.zebra = () => {
  console.log("Zebra mode");
  state.zebra = true
}
// @ts-ignore
window.gps = (gps: string): string => {
  const log = `Stored gps: "${gps}"`
  state.gps = (new Date().toString()) + '|' + gps
  return log
}

// @ts-ignore
window.setNativeMode = (nativeMode: boolean): boolean => {
  console.log('Setting mode native to: ' + nativeMode)
  state.nativeMode = true
  return state.nativeMode
}

export const store = new Vuex.Store({
  state,
  plugins: [UpdatePlanningListener(Vue.prototype.$websocket, navigator, state)],
  mutations: {
    setSystemSettings(state, systemSettings) {
      state.systemSettings = systemSettings
    },
    isOnConsignment(state, show) {
      state.onConsignment = show
    },
    isOnStatusUpdate(state, show) {
      state.onStatusUpdate = show
    },
    setToken(state, { token, storage }) {
      if (token && storage) {
        const user: StoredUser = jwtDecode(token)
        if (user) {
          storage.setItem('Authorization', token)
          state.user = user
          state.jwtToken = token
        }
      }
    },
    setFirebaseToken(state, token) {
      state.firebaseToken = token
    },
    setHideCompletedStops(state, hideCompletedStops) {
      localStorage.setItem("hideCompleteStops", hideCompletedStops.toString())
      state.hideCompletedStops = hideCompletedStops
    },
    clear(state) {
      state.jwtToken = undefined
      state.barcode = undefined
      state.user = undefined
      state.onConsignment = false
    },
    notFound(state, notFound: boolean) {
      state.notFound = notFound
    }
  },
  modules: {
    snackbar,
    transport,
    scan,
    loading
  },
})

export interface MobileTitle {
  title: string
  subtitle: string | undefined
  extendedTitle: string | undefined
}
