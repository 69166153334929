module.exports = {
  "language": "Language",
  "actors": "Actors",
  "ID": "ID",
  "search": "Search",
  "copied": "Copied to clipboard",
  "sort": "Sort {0}",
  "yes": "Yes",
  "no": "No",
  "notifications": {
    "title": "Notifications",
    "empty": "You have no notifications"
  },
  "auth": {
    "logout": "Logout",
    "login": "Login",
    "register": "Register new account"
  },
  "user": {
    "changePassword": "Change password",
    "firstPasswordChange": "You are required to change your password on first login",
    "plural": "Users",
    "single": "User",
    "filters": {
      "locked": "Show locked users"
    },
    "fields": {
      "password": "Password",
      "authorizations": "Authorizations",
      "email": "Email",
      "name": "Name",
      "repeatPassword": "Repeat password"
    },
    "validation": {
      "length": "8 Characters long",
      "uppercase": "One uppercase letter",
      "lowercase": "One lowercase letter",
      "number": "One number",
      "special": "One special character (!@#$%^&*_=+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Phone",
      "company": "Company name",
      "street": "Street",
      "zipCode": "Zip code",
      "city": "City",
      "country": "Country",
      "houseNumber": "House number"
    }
  },
  "errors": {
    "loginWrong": "Username and password do not match. Please try again!",
    "notFound": {
      "title": "Page not found",
      "message": "Oops! This page wasn't found."
    },
    "noInternet": "You accepted the cargo without an active internet connection.\\nPlease check your Wifi or mobile data settings.\\nOnce the connection is reestablished the update will be resent automatically"
  },
  "messages": {
    "sureDelete": "Are you sure you want to delete {0}?",
    "sureSave": "Are you sure you want to save {0}?"
  },
  "tradelane": {
    "fields": {
      "sender": "Sender",
      "receiver": "Receiver"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "License plate"
    }
  },
  "company": {
    "single": "Company"
  },
  "scan": {
    "currentStage": "Current stage",
    "description": "Description",
    "qrCode": "QR Code",
    "locationNotAccessible": "Location not found",
    "noEmballage": "No packaging",
    "noMessage": "No message added",
    "missingCargo": "Missing cargo",
    "shipmentAndBarcodes": "Shipment and barcodes",
    "scanPackages": "Scan packages",
    "emballage": "Emballage",
    "required": "Required",
    "upload": "Upload",
    "statusRequirements": "Status requirements",
    "addPhoto": "Add photo",
    "addAttachments": "Add attachments",
    "addMessage": "Add message",
    "addEmballage": "Add packaging",
    "signOnGlass": "Sign on glass",
    "start": "Start",
    "shelvesExtensions": "Shelves and Extensions",
    "notready": "Not ready",
    "manco": "Missing",
    "fields": {
      "message": "Message",
      "attach": "Connect",
      "detach": "Disconnect"
    },
    "changeAmounts": "Change amounts",
    "damage": "Damage",
    "not": "Not",
    "mancoMessage": "Couldn't scan barcode or package not present? Report it in this field..."
  },
  "sign": {
    "sign": "Sign",
    "signAbsence": "Signed in absence of customer"
  },
  "erp": {
    "single": "ERP",
    "packaging": "Packaging"
  },
  "nav": {
    "finished": "Finished",
    "current": "Current",
    "overview": "Overview",
    "messages": "Messages",
    "shipment": "Shipment",
    "shipments": "Shipments",
    "settings": "Settings",
    "planning": "Joblist",
    "report": "Reports"
  },
  "wallet": {
    "single": "Wallet"
  },
  "common": {
    "info": "Info",
    "status": "Status",
    "location": "Location",
    "contact": "Contact",
    "contacts": "Contacts",
    "photos": "Photos",
    "attachments": "Attachments",
    "identifier": "Identifier",
    "type": "Type",
    "actions": "Actions",
    "name": "Name",
    "value": "Value",
    "tradelane": "Tradelane",
    "date": "Date",
    "actor": "Actor",
    "create": "Create",
    "from": "From",
    "to": "To",
    "general": "General",
    "back": "Back",
    "next": "Next",
    "finish": "Finish",
    "clear": "Clear",
    "notes": "Notes",
    "details": "Details",
    "retry": "Retry",
    "amount": "Amount",
    "trip": "Trip",
    "references": "References",
    "in": "In",
    "out": "Out",
    "senderMessage": "Sender remark",
    "receiverMessage": "Receiver remark",
    "total": "Total"
  },
  "actions": {
    "shipmentCreated": "Shipment created",
    "shipmentUpdated": "Shipment updated",
    "planningCreated": "Planning created",
    "planningUpdated": "Planning updated",
    "accesscodeCreated": "Access code created",
    "accesscodeShared": "Access code shared",
    "notificationSent": "Notification sent",
    "partOfAccesscodeCreated": "Element of created access code for planning",
    "partOfAccesscodeShared": "Element of shared access code for planning",
    "addObj": "Add {0}",
    "add": "Add",
    "save": "Save",
    "saveObj": "Save {0}",
    "copy": "Copy",
    "send": "Send",
    "continue": "Continue",
    "close": "Close"
  },
  "shipment": {
    "eta": "ETA",
    "ata": "ATA",
    "single": "Shipment",
    "status": "Status",
    "finished": "Finalized shipments",
    "pickup": "Pickup",
    "delivery": "Delivery",
    "delivered": "Delivered",
    "pickedUp": "Picked up",
    "fields": {
      "parties": "Parties",
      "createdAt": "Created at",
      "updatedAt": "Updated at"
    },
    "consignment": {
      "single": "Consignment",
      "scanned": {
        "waiting": "{id} saved!\\nStoring data can take some time.\\nYou can proceed with another shipment."
      }
    },
    "containers": {
      "fields": {
        "hsCode": "HS Code",
        "packQuantity": "Pack Quantity",
        "packSize": "Pack size",
        "weight": "Weight"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Contains hazardous goods"
      }
    },
    "documents": {
      "fields": {
        "file": "File"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Freight price"
      }
    },
    "lastStatus": "Last status"
  },
  "accept": {
    "updateShipment": "Update shipment",
    "zeroEmballageMessage": "You haven’t specified any packaging. Please specify a reason below or click finish."
  },
  "apiKeys": {
    "plural": "API Keys",
    "single": "API Key",
    "fields": {
      "key": "Key"
    }
  },
  "planning": {
    "driverInformation": "Driver information"
  },
  "from": "From",
  "for": "For",
  "commissioned_by": "Commisioned by",
  "planning_finished": "Planning finished",
  "sure_question": "Are you sure?",
  "sure_question_cancel": "You are canceling an active stop. Are you sure?"
}