import Vue from 'vue'

// Require in a base component context
const requireComponent = require.context('@/general/components/general', false, /Base[\w-]+\.vue$/)

requireComponent.keys().forEach((fileName: string) => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Register component globally
  Vue.component(fileName.replace('./', '').replace('.vue', ''), componentConfig.default || componentConfig)
})
