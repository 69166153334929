module.exports = {
  "language": "Limba",
  "actors": "Actori",
  "ID": "Id",
  "search": "Căutare",
  "copied": "Copiat în clipboard",
  "sort": "Sortează {0}",
  "yes": "da",
  "no": "Nu",
  "notifications": {
    "title": "Notificări",
    "empty": "Nu aveți notificări"
  },
  "auth": {
    "logout": "Deconectare",
    "login": "Autentificare",
    "register": "Înregistrați un cont nou"
  },
  "user": {
    "changePassword": "Schimbaţi parola",
    "firstPasswordChange": "Jste povinni změnit své heslo při prvním přihlášení",
    "plural": "Utilizatori",
    "single": "Utilizator",
    "filters": {
      "locked": "Afișați utilizatorii blocați"
    },
    "fields": {
      "password": "Parola",
      "authorizations": "Autorizații",
      "email": "E-mail",
      "name": "Nume",
      "repeatPassword": "repeta parola"
    },
    "validation": {
      "length": "8 caractere lungi",
      "uppercase": "O litera mare",
      "lowercase": "O literă mică",
      "number": "Un numar",
      "special": "Un personaj special (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Telefon",
      "company": "Numele companiei",
      "street": "Stradă",
      "zipCode": "Cod postal",
      "city": "Oraș",
      "country": "Țară",
      "houseNumber": "numărul casei"
    }
  },
  "errors": {
    "loginWrong": "Lietotājvārds un parole nesakrīt. Lūdzu mēģiniet vēlreiz!",
    "notFound": {
      "title": "Pagina nu a fost gasita",
      "message": "! Această pagină nu a fost găsită."
    },
    "noInternet": "Hyväksyt lastin ilman aktiivista Internet -yhteyttä."
  },
  "messages": {
    "sureDelete": "\"Vai esat pārliecināts",
    "sureSave": "\"Vai esat pārliecināts"
  },
  "tradelane": {
    "fields": {
      "sender": "Expeditor",
      "receiver": "Receptor"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Înmatriculare"
    }
  },
  "company": {
    "single": "Companie"
  },
  "scan": {
    "currentStage": "Starea curentă",
    "description": "Descriere",
    "qrCode": "Cod QR",
    "locationNotAccessible": "Locația nu a fost găsită",
    "noEmballage": "Fără ambalaje",
    "noMessage": "Nu a adăugat niciun mesaj",
    "missingCargo": "Marfă lipsă",
    "shipmentAndBarcodes": "Expediere și coduri de bare",
    "scanPackages": "Pachetele de scanare",
    "emballage": "Adaugă",
    "required": "Necesar",
    "upload": "Încărcare",
    "statusRequirements": "Cerințe de stare",
    "addPhoto": "Adauga poza",
    "addAttachments": "Adăugați atașamente",
    "addMessage": "Adăugați mesaj",
    "addEmballage": "Adăugați Adaugă",
    "signOnGlass": "Semnați pe sticlă",
    "start": "start",
    "shelvesExtensions": "Rafturi și extensii",
    "notready": "Nu e gata",
    "manco": "Dispărut",
    "fields": {
      "message": "Mesaj",
      "attach": "Conectați",
      "detach": "Deconectat"
    },
    "changeAmounts": "Modificați sumele",
    "damage": "Deteriora",
    "not": "Nu",
    "mancoMessage": "\"Nelze skenovat čárový kód nebo balíček"
  },
  "sign": {
    "sign": "Semn",
    "signAbsence": "Podepsáno v nepřítomnosti zákazníka"
  },
  "erp": {
    "single": "ERP",
    "packaging": "Ambalaj"
  },
  "nav": {
    "finished": "Terminat",
    "current": "Actual",
    "overview": "Prezentare generală",
    "messages": "Mesaje",
    "shipment": "Expediere",
    "shipments": "Livrări",
    "settings": "Setări",
    "planning": "Joblist",
    "report": "Rapoarte"
  },
  "wallet": {
    "single": "Portofel"
  },
  "common": {
    "info": "Informații",
    "status": "stare",
    "location": "Locație",
    "contact": "a lua legatura",
    "contacts": "Contacte",
    "photos": "Fotografii",
    "attachments": "Atașamente",
    "identifier": "Identificator",
    "type": "Tip",
    "actions": "Acțiuni",
    "name": "Nume",
    "value": "Valoare",
    "tradelane": "Tradelane",
    "date": "Data",
    "actor": "Actor",
    "create": "Crea",
    "from": "Din",
    "to": "La",
    "general": "General",
    "back": "Înapoi",
    "next": "Următorul",
    "finish": "finalizarea",
    "clear": "clar",
    "notes": "Note",
    "details": "Detalii",
    "retry": "Din nou",
    "amount": "Cantitate",
    "trip": "Călătorie",
    "references": "Referințe",
    "in": "În",
    "out": "Afară",
    "senderMessage": "Remarcare expeditor",
    "receiverMessage": "Observație de receptor",
    "total": "Total"
  },
  "actions": {
    "shipmentCreated": "Expediere creată",
    "shipmentUpdated": "Expedierea actualizată",
    "planningCreated": "Planificarea creată",
    "planningUpdated": "Planificarea actualizată",
    "accesscodeCreated": "Cod de acces creat",
    "accesscodeShared": "Cod de acces partajat",
    "notificationSent": "Notificare trimisă",
    "partOfAccesscodeCreated": "Elementul codului de acces creat pentru planificare",
    "partOfAccesscodeShared": "Elementul codului de acces partajat pentru planificare",
    "addObj": "Adăugați {0}",
    "add": "Adăuga",
    "save": "Salvați",
    "saveObj": "Salvați {0}",
    "copy": "Copie",
    "send": "Trimite",
    "continue": "Continua",
    "close": "Închide"
  },
  "shipment": {
    "eta": "Eta",
    "ata": "LA UN",
    "single": "Expediere",
    "status": "stare",
    "finished": "Livrări finalizate",
    "pickup": "Ridica",
    "delivery": "Livrare",
    "delivered": "Livrare",
    "pickedUp": "Livrat",
    "fields": {
      "parties": "Petreceri",
      "createdAt": "Creat la",
      "updatedAt": "Actualizat la"
    },
    "consignment": {
      "single": "Zásilka",
      "scanned": {
        "waiting": "{id} salvestatud!"
      }
    },
    "containers": {
      "fields": {
        "hsCode": "Cod HS",
        "packQuantity": "Cantitatea pachetului",
        "packSize": "Dimensiunea pachetului",
        "weight": "Greutate"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Conține bunuri periculoase"
      }
    },
    "documents": {
      "fields": {
        "file": "Fişier"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Preț de marfă"
      }
    },
    "lastStatus": "Ultimul statut"
  },
  "accept": {
    "updateShipment": "Actualizați expedierea",
    "zeroEmballageMessage": " norādiet iemeslu zemāk vai noklikšķiniet uz Pabeigt.\""
  },
  "apiKeys": {
    "plural": "Cheile API",
    "single": "Cheia API",
    "fields": {
      "key": "Cheie"
    }
  },
  "planning": {
    "driverInformation": "Informații despre șofer"
  },
  "from": "Din",
  "for": "Pentru",
  "commissioned_by": "Comisionat de",
  "planning_finished": "Planificarea terminată",
  "sure_question": "Esti sigur?",
  "sure_question_cancel": "Anulați o oprire activă. Esti sigur?"
}