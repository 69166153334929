

















import { Component, Mixins, Prop } from 'vue-property-decorator'
import InputMixin from '@/general/mixins/InputMixin'

@Component
export default class BaseTextField extends Mixins(InputMixin) {
  @Prop({ default: false }) autoComplete!: boolean
  @Prop({ default: 'text' }) typeInput!: string
}
