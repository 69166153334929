module.exports = {
  "language": "Kieli",
  "actors": "Näyttelijät",
  "ID": "Henkilöllisyystodistus",
  "search": "Hae",
  "copied": "Kopioitu leikepöydälle",
  "sort": "Lajittele {0}",
  "yes": "Joo",
  "no": "Ei",
  "notifications": {
    "title": "Ilmoitukset",
    "empty": "Sinulla ei ole ilmoituksia"
  },
  "auth": {
    "logout": "Kirjautua ulos",
    "login": "Kirjaudu sisään",
    "register": "Rekisteröi uusi tili"
  },
  "user": {
    "changePassword": "Vaihda salasana",
    "firstPasswordChange": "Sinun on vaihdettava salasanasi ensimmäisessä kirjautumisessa",
    "plural": "Käyttäjät",
    "single": "Käyttäjä",
    "filters": {
      "locked": "Näytä lukitut käyttäjät"
    },
    "fields": {
      "password": "Salasana",
      "authorizations": "Valtuutus",
      "email": "Sähköposti",
      "name": "Nimi",
      "repeatPassword": "Toista salasana"
    },
    "validation": {
      "length": "8 merkkiä pitkä",
      "uppercase": "Yksi iso kirjain",
      "lowercase": "Yksi pieni kirjain",
      "number": "Yksi numero",
      "special": "Yksi erikoishahmo (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Puhelin",
      "company": "Yrityksen nimi",
      "street": "Katu",
      "zipCode": "Postinumero",
      "city": "Kaupunki",
      "country": "Maa",
      "houseNumber": "talonumero"
    }
  },
  "errors": {
    "loginWrong": " попробуйте еще раз!\"",
    "notFound": {
      "title": "Sivua ei löydetty",
      "message": "Oho! Tätä sivua ei löytynyt."
    },
    "noInternet": " a atualização será ressentida automaticamente\""
  },
  "messages": {
    "sureDelete": "\"Вы уверены",
    "sureSave": "\"Вы уверены"
  },
  "tradelane": {
    "fields": {
      "sender": "Lähettäjä",
      "receiver": "Vastaanotin"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Rekisterikilpi"
    }
  },
  "company": {
    "single": "Yhtiö"
  },
  "scan": {
    "currentStage": "Nykyinen vaihe",
    "description": "Kuvaus",
    "qrCode": "QR koodi",
    "locationNotAccessible": "Sijainti ei löydy",
    "noEmballage": "Ei pakkausta",
    "noMessage": "Ei viestiä lisätty",
    "missingCargo": "Puuttuva lasti",
    "shipmentAndBarcodes": "Lähetys ja viivakoodit",
    "scanPackages": "Skannauspaketit",
    "emballage": "Kisko",
    "required": "Vaaditaan",
    "upload": "Ladata",
    "statusRequirements": "Tilavaatimukset",
    "addPhoto": "Lisää valokuva",
    "addAttachments": "Lisää liitteet",
    "addMessage": "Lisätä viestiä",
    "addEmballage": "Lisää emballage",
    "signOnGlass": "Kirjaudu sisään",
    "start": "alkaa",
    "shelvesExtensions": "Hyllyt ja laajennukset",
    "notready": "Ei ole valmis",
    "manco": "Puuttuva",
    "fields": {
      "message": "Viesti",
      "attach": "Kytkeä",
      "detach": "Katkaista"
    },
    "changeAmounts": "Muuttaa",
    "damage": "Vahingoittaa",
    "not": "Ei",
    "mancoMessage": "Kunne ikke skanne strekkode eller pakke ikke til stede? Rapporter det på dette feltet ..."
  },
  "sign": {
    "sign": "Merkki",
    "signAbsence": "Allekirjoitettu asiakkaan puuttuessa"
  },
  "erp": {
    "single": "ERP",
    "packaging": "Pakkaus"
  },
  "nav": {
    "finished": "Valmis",
    "current": "Nykyinen",
    "overview": "Yleiskatsaus",
    "messages": "Viestit",
    "shipment": "Lähetys",
    "shipments": "Lähetykset",
    "settings": "asetukset",
    "planning": "Yhdistelmä",
    "report": "Raportit"
  },
  "wallet": {
    "single": "Lompakko"
  },
  "common": {
    "info": "Tiedot",
    "status": "Tila",
    "location": "Sijainti",
    "contact": "Ottaa yhteyttä",
    "contacts": "Kontaktit",
    "photos": "Kuvat",
    "attachments": "Liitetiedot",
    "identifier": "Tunniste",
    "type": "Tyyppi",
    "actions": "Toiminnot",
    "name": "Nimi",
    "value": "Arvo",
    "tradelane": "Kaupankäynti",
    "date": "Päivämäärä",
    "actor": "Näyttelijä",
    "create": "Luoda",
    "from": "-Sta",
    "to": "-Lla",
    "general": "Yleinen",
    "back": "Takaisin",
    "next": "Seuraava",
    "finish": "Suorittaa loppuun",
    "clear": "Asia selvä",
    "notes": "Muistiinpanot",
    "details": "Yksityiskohdat",
    "retry": "Uudelleenarvioida",
    "amount": "Määrä",
    "trip": "Matka",
    "references": "Viitteet",
    "in": "Sisään",
    "out": "Ulkona",
    "senderMessage": "Lähettäjä Huomautus",
    "receiverMessage": "Vastaanottimen huomautus",
    "total": "Kaikki yhteensä"
  },
  "actions": {
    "shipmentCreated": "Lähetys luotu",
    "shipmentUpdated": "Lähetys päivitetty",
    "planningCreated": "Suunnittelu luotu",
    "planningUpdated": "Suunnittelu päivitetty",
    "accesscodeCreated": "Luotu pääsykoodi",
    "accesscodeShared": "Pääsykoodi jaettu",
    "notificationSent": "Ilmoitus lähetetty",
    "partOfAccesscodeCreated": "Luodun pääsykoodin elementti suunnitteluun",
    "partOfAccesscodeShared": "Jaetun pääsykoodin elementti suunnittelua varten",
    "addObj": "Lisää {0}",
    "add": "Lisätä",
    "save": "Tallentaa",
    "saveObj": "Tallenna {0}",
    "copy": "Kopio",
    "send": "Lähettää",
    "continue": "Jatkaa",
    "close": "kiinni"
  },
  "shipment": {
    "eta": "ETA",
    "ata": "ATA -ATA",
    "single": "Lähetys",
    "status": "Tila",
    "finished": "Viimeisteltyjä lähetyksiä",
    "pickup": "Noukkia",
    "delivery": "Toimitus",
    "delivered": "Toimitus",
    "pickedUp": "Toimitettu",
    "fields": {
      "parties": "Puolueet",
      "createdAt": "Luotu jhk",
      "updatedAt": "Päivittynyt"
    },
    "consignment": {
      "single": "Lähetys",
      "scanned": {
        "waiting": "{id} Sparade! \\ NStoring Data kan ta lite tid. \\ Nyou kan fortsätta med en annan leverans."
      }
    },
    "containers": {
      "fields": {
        "hsCode": "HS -koodi",
        "packQuantity": "Pakkausmäärä",
        "packSize": "Pakkauskoko",
        "weight": "Paino"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Sisältää vaarallisia tuotteita"
      }
    },
    "documents": {
      "fields": {
        "file": "Tiedosto"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Tavarahinta"
      }
    },
    "lastStatus": "Viimeinen tila"
  },
  "accept": {
    "updateShipment": "Päivitä lähetys",
    "zeroEmballageMessage": " укажите причину ниже или нажмите «Закончить».\""
  },
  "apiKeys": {
    "plural": "API -avaimet",
    "single": "API -avain",
    "fields": {
      "key": "Avain"
    }
  },
  "planning": {
    "driverInformation": "Kuljettajatiedot"
  },
  "from": "-Sta",
  "for": "Puolesta",
  "commissioned_by": "Osoitettu",
  "planning_finished": "Suunnittelu valmis",
  "sure_question": "Oletko varma?",
  "sure_question_cancel": "Peruutat aktiivisen pysäkin. Oletko varma?"
}