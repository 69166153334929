module.exports = {
  "language": "Langue",
  "actors": "Associé",
  "ID": "ID",
  "search": "Chercher",
  "copied": "Copié dans le presse-papiers",
  "sort": "Sorte {0}",
  "yes": "Oui",
  "no": "Non",
  "notifications": {
    "title": "Notifications",
    "empty": "Vous n'avez aucune notification"
  },
  "auth": {
    "logout": "Déconnexion",
    "login": "Reconnectez-vous",
    "register": "Créer un compte"
  },
  "user": {
    "changePassword": "Changer le mot de passe",
    "firstPasswordChange": "Vous devez changer votre mot de passe après la première connexion.",
    "plural": "Utilisateurs",
    "single": "Utilisateur",
    "filters": {
      "locked": "Afficher les utilisateurs verrouillés"
    },
    "fields": {
      "password": "Mot de passe",
      "authorizations": "Pouvoirs",
      "email": "E-mail",
      "name": "Nom",
      "repeatPassword": "Répéter le mot de passe"
    },
    "validation": {
      "length": "8 caractères de long",
      "uppercase": "Une lettre majuscule",
      "lowercase": "Une lettre minuscule",
      "number": "Un certain numbre",
      "special": "Un caractère spécial (!@#$%^&*_=+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Téléphone",
      "company": "Nom de la société",
      "street": "Rue",
      "zipCode": "Code postal",
      "city": "Ville",
      "country": "Pays",
      "houseNumber": "Numéro de maison"
    }
  },
  "errors": {
    "loginWrong": "Le nom d'utilisateur et le mot de passe ne correspondent pas.\\n Veuillez réessayer!",
    "notFound": {
      "title": "Page non trouvée",
      "message": "Oups! Cette page est introuvable."
    },
    "noInternet": "Je hebt de vracht geaccepteerd zonder actieve internet verbinding.\\nControleer je Wifi of mobiele data instellingen\\nAls de verbinding is hersteld zal de acceptatie automatisch opnieuw verstuurd worden"
  },
  "messages": {
    "sureDelete": " dass Sie löschen möchten {0}?\"",
    "sureSave": " dass Sie sparen möchten {0}?\""
  },
  "tradelane": {
    "fields": {
      "sender": "Expéditeur",
      "receiver": "Récepteur"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Insigne"
    }
  },
  "company": {
    "single": "Compagnie"
  },
  "scan": {
    "currentStage": "Stade actuel",
    "description": "Description",
    "qrCode": "QR Code",
    "locationNotAccessible": "emplacement introuvable",
    "noEmballage": "Pas d'emballage",
    "noMessage": "Aucun message ajouté",
    "missingCargo": "Cargaison manquante",
    "shipmentAndBarcodes": "Expédition et codes à barres",
    "scanPackages": "Scan Les packs",
    "emballage": "Accablier",
    "required": "Requis",
    "upload": "Télécharger",
    "statusRequirements": "Conditions de statut",
    "addPhoto": "Ajouter une photo",
    "addAttachments": "Ajouter des pièces jointes",
    "addMessage": "Ajouter un message",
    "addEmballage": "Ajouter un emballage",
    "signOnGlass": "Ajouter une signature",
    "start": "Début",
    "shelvesExtensions": "Étagères et extensions",
    "notready": "Pas prêt",
    "manco": "Disparu",
    "fields": {
      "message": "Message",
      "attach": "Relier",
      "detach": "Débrancher"
    },
    "changeAmounts": "Modifier les montants",
    "damage": "Dégâts",
    "not": "Pas",
    "mancoMessage": "NE NE PAS SCANTER LA CODE DE BARS OU LE Package n'est-il pas présent? Signalez-le dans ce domaine ..."
  },
  "sign": {
    "sign": "Signe",
    "signAbsence": "Signé en l'absence de client"
  },
  "erp": {
    "single": "ERP",
    "packaging": "Emballage"
  },
  "nav": {
    "finished": "Terminé",
    "current": "Courant",
    "overview": "Aperçu",
    "messages": "Des messages",
    "shipment": "Embellissement",
    "shipments": "Embellissements",
    "settings": "Réglages",
    "planning": "Liste d'emplois",
    "report": "Reportage"
  },
  "wallet": {
    "single": "Portefeuille"
  },
  "common": {
    "info": "Info",
    "status": "Statut",
    "location": "Emplacement",
    "contact": "Contact",
    "contacts": "Les Contacts",
    "photos": "Photos",
    "attachments": "Pièces jointes",
    "identifier": "Identifiant",
    "type": "Tapez",
    "actions": "Actions",
    "name": "Nom",
    "value": "Valeur",
    "tradelane": "Chaîne d'approvisionnement",
    "date": "Date",
    "actor": "Acteur",
    "create": "Créer",
    "from": "De",
    "to": "Jusqu'à",
    "general": "Général",
    "back": "Retour",
    "next": "Suivante",
    "finish": "Arrondir",
    "clear": "Encore",
    "notes": "Remarques",
    "details": "Détails",
    "retry": "Réessayez",
    "amount": "Nombre de",
    "trip": "Voyage",
    "references": "Références",
    "in": "Dans",
    "out": "Dehors",
    "senderMessage": "Remarque de l'expéditeur",
    "receiverMessage": "Remarque du récepteur",
    "total": "Total"
  },
  "actions": {
    "shipmentCreated": "Envoi créé",
    "shipmentUpdated": "Envoi mise à jour",
    "planningCreated": "Planification créée",
    "planningUpdated": "Planification mise à jour",
    "accesscodeCreated": "Code d'accès créé",
    "accesscodeShared": "Code d'accès partagé",
    "notificationSent": "Notification envoyée",
    "partOfAccesscodeCreated": "Élément du code d'accès créé pour la planification",
    "partOfAccesscodeShared": "Élément du code d'accès partagé pour la planification",
    "addObj": "Ajouter {0}",
    "add": "Ajouter",
    "save": "Enregister",
    "saveObj": "Enregister {0}",
    "copy": "Copier",
    "send": "Envoyer",
    "continue": "Continuer",
    "close": "proche"
  },
  "shipment": {
    "eta": "ETA",
    "ata": "ATA",
    "single": "Expédition",
    "status": "Statut",
    "finished": "Expéditions terminées",
    "pickup": "Rammasage",
    "delivery": "La livraision",
    "delivered": "Livraison",
    "pickedUp": "Livré",
    "fields": {
      "parties": "Fêtes",
      "createdAt": "Créé le",
      "updatedAt": "Mis à jour le"
    },
    "consignment": {
      "single": "Consignation",
      "scanned": {
        "waiting": " bitte warten Sie.\\nOder scannen Sie die nächste Ladung weiter.\""
      }
    },
    "containers": {
      "fields": {
        "hsCode": "Code SH",
        "packQuantity": "Nombre de colis",
        "packSize": "Pièces par emballage",
        "weight": "Le poids"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Contient des marchandises dangereuses"
      }
    },
    "documents": {
      "fields": {
        "file": "Fichier"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Taux de fret"
      }
    },
    "lastStatus": "Dernier statut"
  },
  "accept": {
    "updateShipment": "Mettre la cargaison",
    "zeroEmballageMessage": "Vous n'avez spécifié aucun emballage. Veuillez spécifier une raison ci-dessous ou cliquez sur Terminer."
  },
  "apiKeys": {
    "plural": "API Keys",
    "single": "API Key",
    "fields": {
      "key": "Key"
    }
  },
  "planning": {
    "driverInformation": "Chauffeur info"
  },
  "from": "De",
  "for": "Pour",
  "commissioned_by": "Commis à",
  "planning_finished": "Planification terminée",
  "sure_question": "Êtes-vous sûr?",
  "sure_question_cancel": "Vous annulez un arrêt actif. Êtes-vous sûr?"
}