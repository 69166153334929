import { openDB } from 'idb'
import {Database, ServiceWorkerDatabase} from '@/general/models/Database'
import {GetPlanning_GetPlanning} from "@/generated/GetPlanning";
import {GetConsignment_GetConsignment} from "@/generated/GetConsignment";
import { GetECMRSByPlanning_GetECMRSByPlanning_items_value } from '@/generated/GetECMRSByPlanning';

const database = openDB<Database>('Cargoledger', 8, {
  upgrade(db) {
    if (db.objectStoreNames.contains('planning')) {
      db.deleteObjectStore('planning')
    }
    if (db.objectStoreNames.contains('consignment')) {
      db.deleteObjectStore('consignment')
    }
    if (db.objectStoreNames.contains('ecmr')) {
      db.deleteObjectStore('ecmr')
    }

    db.createObjectStore('planning')
    db.createObjectStore('consignment')
    db.createObjectStore('ecmr')
  }
})

const serviceWorkerDatabase = openDB<ServiceWorkerDatabase>('Service-Worker', 1, {
    upgrade(db) {
        if (db.objectStoreNames.contains('planning-updated')) {
            db.deleteObjectStore('planning-updated')
        }

        db.createObjectStore('planning-updated')
    }
})


export async function saveECMRSToDatabase(ecmrs: Map<Number, GetECMRSByPlanning_GetECMRSByPlanning_items_value[]>) {
  database.then(database => database.put('ecmr', ecmrs, 'first'))
}

export async function getECMRSFromDatabase(): Promise<Map<Number, GetECMRSByPlanning_GetECMRSByPlanning_items_value[]> | undefined> {
  return database.then(database => database.get('ecmr', 'first'))
}

export async function savePlanningToDatabase(planning: GetPlanning_GetPlanning, accesstoken: string | null) {
    database.then(database => database.clear('planning'))
    if (accesstoken) {
        database.then(database => database.put('planning', planning, accesstoken))
    }
    database.then(database => database.put('planning', planning, 'first'))
}

export async function getPlanningFromDatabase(accesstoken: string | null): Promise<GetPlanning_GetPlanning | undefined> {
    if (accesstoken) {
        database.then(database => database.get('planning', 'first'))
        return database.then(database => database.get('planning', accesstoken))
    } else {
        return database.then(database => database.get('planning', 'first'))
    }
}

export async function saveConsignmentToDatabase(consignment: GetConsignment_GetConsignment) {
  database.then(database => {
    const date = new Date()
    return database.put(
      'consignment',
      {
        consignment,
        date
      },
      consignment.id
    )
  })
  getAllConsignmentsFromDatabase().then(consignments => {
    if (consignments && consignments.length > 10) {
      removeConsignmentFromDatabase(
        consignments[consignments.length - 1].id
      )
    }
  })
}

export async function getConsignmentFromDatabase(consignmentId: number): Promise<GetConsignment_GetConsignment | undefined> {
  return database.then(database => database.get('consignment', consignmentId).then(ci => ci?.consignment))
}

export async function removeConsignmentFromDatabase(consignmentId: number) {
  return database.then(database => database.delete('consignment', consignmentId))
}

export async function getAllConsignmentsFromDatabase(): Promise<GetConsignment_GetConsignment[] | undefined> {
  return database.then(database =>
    database.getAll('consignment').then(cis =>
      cis
        .sort((cia, cib) => {
          if (cia.date > cib.date) {
            return -1
          } else {
            return 1
          }
        })
        .map(ci => ci.consignment)
    )
  )
}

export async function findConsignmentFromDatabase(consignmentId: number): Promise<GetConsignment_GetConsignment | undefined> {
  return new Promise<GetConsignment_GetConsignment>((resolve, reject) => {
    database
      .then(database => database.get('consignment', consignmentId))
      .then(value => {
        if (value) {
          resolve(value.consignment)
        } else {
          getPlanningFromDatabase(null).then((planning: GetPlanning_GetPlanning | undefined) => {
            if (planning) {
              resolve(
                findConsignmentInArray(
                  planning.consignments,
                  consignmentId
                )
              )
            } else {
              resolve()
            }
          })
        }
      })
      .catch(reason => reject(reason))
  })
}

export async function findAllPlanningUpdatedMessages(): Promise<string[] | undefined> {
    return serviceWorkerDatabase.then(database => database.getAllKeys('planning-updated'))
}

export async function deleteAllPlanningUpdatedMessages() {
    serviceWorkerDatabase.then(database => database.clear('planning-updated'))
}

function findConsignmentInArray(consignments: GetConsignment_GetConsignment[], consignmentId: number) {
  return consignments.find((consignment: GetConsignment_GetConsignment) => consignment.id === consignmentId)
}
