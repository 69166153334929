module.exports = {
  "language": "Sprache",
  "actors": "Partner",
  "ID": "ID",
  "search": "Suche",
  "copied": "In die Zwischenablage kopiert",
  "sort": "Sortieren {0}",
  "yes": "Ja",
  "no": "Nein",
  "notifications": {
    "title": "Benachrichtungen",
    "empty": "Sie haben keine Benachrichtungen"
  },
  "auth": {
    "logout": "Ausloggen",
    "login": "Einloggen",
    "register": "Registrieren Sie ein neues Konto"
  },
  "user": {
    "changePassword": "Passwort ändern",
    "firstPasswordChange": "Sie müssen Ihr Passwort bei der ersten Anmeldung ändern",
    "plural": "Benutzer",
    "single": "Benutzer",
    "filters": {
      "locked": "Gesperrte Benutzer anzeigen"
    },
    "fields": {
      "password": "Passwort",
      "authorizations": "Berechtigungen",
      "email": "Email",
      "name": "Name",
      "repeatPassword": "Wiederhole das Passwort"
    },
    "validation": {
      "length": "8 Zeichen lang",
      "uppercase": "Ein Großbuchstabe",
      "lowercase": "Ein kleiner Buchstabe",
      "number": "Ein Nummer",
      "special": "ein besonderer Charakter (!@#$%^&*_=+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Telefon",
      "company": "Name der Firma",
      "street": "Straße",
      "zipCode": "Postleitzahl",
      "city": "Stadt",
      "country": "Land",
      "houseNumber": "Hausnummer"
    }
  },
  "errors": {
    "loginWrong": "Benutzername und Passwort stimmen nicht überein.  Bitte versuche es erneut.",
    "notFound": {
      "title": "Seite nicht gefunden",
      "message": "Oops! Diese Seite wurde nicht gefunden."
    },
    "noInternet": " l'acceptation sera automatiquement renvoyée\""
  },
  "messages": {
    "sureDelete": "\"Sind Sie sicher",
    "sureSave": "\"Sind Sie sicher"
  },
  "tradelane": {
    "fields": {
      "sender": "Absender",
      "receiver": "Empfänger"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Nummernschild"
    }
  },
  "company": {
    "single": "Unternehmen"
  },
  "scan": {
    "currentStage": "Das momentane Stadium",
    "description": "Beschreibung",
    "qrCode": "QR-Code",
    "locationNotAccessible": "Ort nicht gefunden",
    "noEmballage": "Keine Verpackung",
    "noMessage": "Keine Nachricht hinzugefügt",
    "missingCargo": "Fehlende Fracht",
    "shipmentAndBarcodes": "Versand und Barcodes",
    "scanPackages": "Pakete scannen",
    "emballage": "Emballage",
    "required": "Erforderlich",
    "upload": "Hochladen",
    "statusRequirements": "Statusanforderungen",
    "addPhoto": "Foto hinzufügen",
    "addAttachments": "Anhänge hinzufügen",
    "addMessage": "Nachricht hinzufügen",
    "addEmballage": "Emballage hinzufügen",
    "signOnGlass": "Signatur hinzufügen",
    "start": "Anfang",
    "shelvesExtensions": "Regale und Verlängerungen",
    "notready": "Nicht bereit",
    "manco": "Fehlen",
    "fields": {
      "message": "Botschaft",
      "attach": "Verbinden",
      "detach": "Trennen"
    },
    "changeAmounts": "Mengen ändern",
    "damage": "Schaden",
    "not": "Nicht",
    "mancoMessage": "Könnte kein Barcode oder Paket nicht vorhanden sein? Melden Sie es in diesem Bereich ..."
  },
  "sign": {
    "sign": "Zeichen",
    "signAbsence": "Ohne Kunde signiert"
  },
  "erp": {
    "single": "ERP",
    "packaging": "Verpackung"
  },
  "nav": {
    "finished": "Fertig",
    "current": "Strom",
    "overview": "Überblick",
    "messages": "Mitteilungen",
    "shipment": "Sendung",
    "shipments": "Sendungen",
    "settings": "Einstellungen",
    "planning": "Job-liste",
    "report": "Berichte"
  },
  "wallet": {
    "single": "Wallet"
  },
  "common": {
    "info": "Info",
    "status": "Status",
    "location": "Lage",
    "contact": "Kontakt",
    "contacts": "Kontakte",
    "photos": "Fotos",
    "attachments": "Anhänge",
    "identifier": "Kennung",
    "type": "Art",
    "actions": "Aktionen",
    "name": "Name",
    "value": "Wert",
    "tradelane": "Lieferkette",
    "date": "Datum",
    "actor": "Partner",
    "create": "Erstellen",
    "from": "Von",
    "to": "Zu",
    "general": "Allgemeines",
    "back": "Zurück",
    "next": "Nächster",
    "finish": "Beenden",
    "clear": "Löschen",
    "notes": "Sprechzettel",
    "details": "Details",
    "retry": "Wiederholen",
    "amount": "Anzahl von",
    "trip": "Reise",
    "references": "Verweise",
    "in": "Im",
    "out": "aus",
    "senderMessage": "Absender Bemerkung",
    "receiverMessage": "Empfänger Bemerkung",
    "total": "Gesamt"
  },
  "actions": {
    "shipmentCreated": "Sendung Erstellt",
    "shipmentUpdated": "Sendung Aktualisiert",
    "planningCreated": "Planung erstellt",
    "planningUpdated": "Planung aktualisiert",
    "accesscodeCreated": "Zugriffscode erstellt",
    "accesscodeShared": "Zugriffscode gemeinsam genutzt",
    "notificationSent": "Benachrichtigung gesendet",
    "partOfAccesscodeCreated": "Element des erstellten Zugriffscode für die Planung",
    "partOfAccesscodeShared": "Element des gemeinsam genutzten Zugriffscode für die Planung",
    "addObj": "Hinzufügen {0}",
    "add": "Hinzufügen",
    "save": "Save",
    "saveObj": "Save {0}",
    "copy": "Kopiere",
    "send": "Senden",
    "continue": "Verder",
    "close": "Nah dran"
  },
  "shipment": {
    "eta": "ETA",
    "ata": "ATA",
    "single": "Sendung",
    "status": "Status",
    "finished": "Abgeschlossene Sendungen",
    "pickup": "Abholen",
    "delivery": "Lieferung",
    "delivered": "Die Zustellung",
    "pickedUp": "Geliefert",
    "fields": {
      "parties": "Parteien",
      "createdAt": "Hergestellt in",
      "updatedAt": "Aktualisiert am"
    },
    "consignment": {
      "single": "Sendung",
      "scanned": {
        "waiting": "\"Data wirdt bearbeitet"
      }
    },
    "containers": {
      "fields": {
        "hsCode": "HS-Code",
        "packQuantity": "Pack Menge",
        "packSize": "Paket Größe",
        "weight": "Gewicht"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Enthält gefährliche Güter"
      }
    },
    "documents": {
      "fields": {
        "file": "File"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Frachtpreis"
      }
    },
    "lastStatus": "Letzer status"
  },
  "accept": {
    "updateShipment": "Versand aktualisieren",
    "zeroEmballageMessage": "Sie haben keine Verpackung angegeben. Bitte geben Sie unten einen Grund an oder klicken Sie auf Fertig stellen."
  },
  "apiKeys": {
    "plural": "API -Schlüssel",
    "single": "API-Schlüssel",
    "fields": {
      "key": "Key"
    }
  },
  "planning": {
    "driverInformation": "Treiberinfo"
  },
  "from": "Aus",
  "for": "Zum",
  "commissioned_by": "Aufgenommen von",
  "planning_finished": "Planung beendet",
  "sure_question": "Bist du dir sicher?",
  "sure_question_cancel": "Sie stornieren einen aktiven Stopp. Bist du sicher?"
}