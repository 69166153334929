


























































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import InputMixin from '@/general/mixins/InputMixin'

@Component
export default class BaseDateTimePicker extends Mixins(InputMixin) {
  @Prop() value: Date | null
  @Prop({ required: false, default: true }) requiresTime: boolean

  valueProp: string = ''

  dialog: boolean = false
  time: string = ''
  date: string = ''
  tab = 0

  created() {
    if (this.value) {
      this.tab = 1
      this.watchValue(this.value)
    }
  }

  emitTime(type: string, time: string) {
    this.time = time
    this.emit(type)
  }

  emitDate(type: string, date: string) {
    this.date = date
    this.emit(type)

    if (this.requiresTime) {
      this.tab = 1
    }
  }

  emit(type: string) {
    if (this.time && this.date) {
      const value = new Date(this.date + 'T' + this.time)
      this.valueProp = value.toString()
      this.$emit(type, value)
    } else if(!this.requiresTime) {
      const value = new Date(this.date)
      this.valueProp = value.toString()
      this.$emit(type, value)
    }
  }

  getValue(): string {
    if (this.requiresTime) {
      return this.valueProp
    } else {
      return this.$options.filters!!.dateNoTime((new Date(this.valueProp).getTime()))
    }
  }

  @Watch('value')
  watchValue(value: Date) {
    this.valueProp = value.toString()
  }
}
