import { State as RootState } from './index'
import { Module } from 'vuex'

class State {
  loading: boolean = true
}

const store: Module<State, RootState> = {
  state: new State(),
  namespaced: true,
  mutations: {
    set(state, loading: boolean) {
      state.loading = loading
    }
  }
}

export default store
