module.exports = {
  "language": "Język",
  "actors": "Aktorzy",
  "ID": "ID",
  "search": "Szukaj",
  "copied": "Skopiowane do schowka",
  "sort": "Sort {0}",
  "yes": "TAk",
  "no": "Nie",
  "notifications": {
    "title": "Powiadomienia",
    "empty": "Nie masz powiadomień"
  },
  "auth": {
    "logout": "Wyloguj",
    "login": "Zaloguj sie",
    "register": "Zarejestruj Nowe Konto"
  },
  "user": {
    "changePassword": "Zmień hasło",
    "firstPasswordChange": "Musisz zmienić hasło w pierwszym logowaniu",
    "plural": "Użytkownicy",
    "single": "Użytkownik",
    "filters": {
      "locked": "Pokaż zablokowanych użytkowników"
    },
    "fields": {
      "password": "Hasło",
      "authorizations": "Autoryzacje",
      "email": "E-mail",
      "name": "Nazwa",
      "repeatPassword": "Powtórz hasło"
    },
    "validation": {
      "length": "8 znaków długości",
      "uppercase": "Jednej dużej litery",
      "lowercase": "Jedna mała litera",
      "number": "Jeden numer",
      "special": "Jedna postać specjalna (!@$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Telefon",
      "company": "Nazwa firmy",
      "street": "Ulica",
      "zipCode": "Kod pocztowy",
      "city": "Miasto",
      "country": "Kraj",
      "houseNumber": "numer domu"
    }
  },
  "errors": {
    "loginWrong": " опитайте отново!\"",
    "notFound": {
      "title": "Strona nie znaleziona",
      "message": "Ups! Ta strona nie została znaleziona."
    },
    "noInternet": "\"Du accepterede lasten uden en aktiv internetforbindelse. \\ Nplease Kontroller dine wifi- eller mobildataindstillinger. \\ Nonce Forbindelsen er genoprettet"
  },
  "messages": {
    "sureDelete": " at du vil slette {0}?\"",
    "sureSave": " at du vil gemme {0}?\""
  },
  "tradelane": {
    "fields": {
      "sender": "Nadawca",
      "receiver": "Odbiorca"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Tablica rejestracyjna"
    }
  },
  "company": {
    "single": "Firma"
  },
  "scan": {
    "currentStage": "Obecny stan",
    "description": "Opis",
    "qrCode": "Kod QR",
    "locationNotAccessible": "Nie znaleziono lokalizacji",
    "noEmballage": "Bez opakowania",
    "noMessage": "Brak dodania wiadomości",
    "missingCargo": "Brakujące ładunek",
    "shipmentAndBarcodes": "Przesyłka i kody kreskowe",
    "scanPackages": "Pakiety skanowania",
    "emballage": "Zgysk",
    "required": "Wymagany",
    "upload": "Wgrywać",
    "statusRequirements": "Wymagania dotyczące statusu",
    "addPhoto": "Dodaj zdjęcie",
    "addAttachments": "Dodaj załączniki",
    "addMessage": "Dodaj wiadomość",
    "addEmballage": "Dodaj osłonę",
    "signOnGlass": "Znak na szkle",
    "start": "Początek",
    "shelvesExtensions": "Półki i rozszerzenia",
    "notready": "Nie gotowy",
    "manco": "Zaginiony",
    "fields": {
      "message": "Wiadomość",
      "attach": "Łączyć",
      "detach": "Rozłączyć się"
    },
    "changeAmounts": "Zmiana kwot",
    "damage": "Szkoda",
    "not": "Nie",
    "mancoMessage": "Czy nie możesz skanować kodu kreskowego ani pakietu nie obecne? Zgłoś to w tej dziedzinie ..."
  },
  "sign": {
    "sign": "Podpisać",
    "signAbsence": "Podpisane z powodu braku klienta"
  },
  "erp": {
    "single": "ERP",
    "packaging": "Opakowanie"
  },
  "nav": {
    "finished": "Skończone",
    "current": "Aktualny",
    "overview": "Przegląd",
    "messages": "Wiadomości",
    "shipment": "Wysyłka",
    "shipments": "Przesyłki",
    "settings": "Ustawienia",
    "planning": "Lista pracy",
    "report": "Raporty"
  },
  "wallet": {
    "single": "Portfel"
  },
  "common": {
    "info": "Informacje",
    "status": "Status",
    "location": "Lokalizacja",
    "contact": "Kontakt",
    "contacts": "Łączność",
    "photos": "Zdjęcia",
    "attachments": "Załączniki",
    "identifier": "Identyfikator",
    "type": "Rodzaj",
    "actions": "działania",
    "name": "Nazwa",
    "value": "Wartość",
    "tradelane": "Tradelane",
    "date": "Data",
    "actor": "Aktor",
    "create": "Tworzyć",
    "from": "Z",
    "to": "Do",
    "general": "Ogólny",
    "back": "Z powrotem",
    "next": "Następny",
    "finish": "Skończyć",
    "clear": "Jasne",
    "notes": "Notatki",
    "details": "Detale",
    "retry": "Spróbować ponownie",
    "amount": "Kwota",
    "trip": "Wycieczka",
    "references": "Bibliografia",
    "in": "W",
    "out": "Na zewnątrz",
    "senderMessage": "Uwaga nadawcy",
    "receiverMessage": "Uwaga odbiorcza",
    "total": "Całkowity"
  },
  "actions": {
    "shipmentCreated": "Wysyłka utworzona",
    "shipmentUpdated": "Zaktualizowana wysyłka",
    "planningCreated": "Planowanie stworzone",
    "planningUpdated": "Planowanie zaktualizowane",
    "accesscodeCreated": "Utworzony kod dostępu",
    "accesscodeShared": "Udostępniony kod dostępu",
    "notificationSent": "Powiadomienie wysłane",
    "partOfAccesscodeCreated": "Element utworzonego kodu dostępu do planowania",
    "partOfAccesscodeShared": "Element współdzielonego kodu dostępu do planowania",
    "addObj": "Dodaj {0}",
    "add": "Dodać",
    "save": "Ratować",
    "saveObj": "Zapisz {0}",
    "copy": "Kopiuj",
    "send": "Wysłać",
    "continue": "Kontynuować",
    "close": "Blisko"
  },
  "shipment": {
    "eta": "Eta",
    "ata": "Ata",
    "single": "Wysyłka",
    "status": "Status",
    "finished": "Zamienne przesyłki",
    "pickup": "Ulec poprawie",
    "delivery": "Dostawa",
    "delivered": "Dostawa",
    "pickedUp": "Dostarczony",
    "fields": {
      "parties": "Imprezy",
      "createdAt": "Utworzony w",
      "updatedAt": "Zaktualizowano w"
    },
    "consignment": {
      "single": "Przesyłka",
      "scanned": {
        "waiting": "{Id} gemt! \\ NStoring Data kan tage nogen tid. \\ NYOU kan fortsætte med en anden forsendelse."
      }
    },
    "containers": {
      "fields": {
        "hsCode": "Kod HS",
        "packQuantity": "Ilość pakietu",
        "packSize": "Rozmiar paczki",
        "weight": "Waga"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Zawiera towary niebezpieczne"
      }
    },
    "documents": {
      "fields": {
        "file": "Plik"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Cena frachtu"
      }
    },
    "lastStatus": "Ostatni status"
  },
  "accept": {
    "updateShipment": "Zaktualizuj wysyłkę",
    "zeroEmballageMessage": "\"Не сте посочили никаква опаковка. Моля"
  },
  "apiKeys": {
    "plural": "Klucze API",
    "single": "Klucz API",
    "fields": {
      "key": "Klucz"
    }
  },
  "planning": {
    "driverInformation": "Informacje o sterowniku"
  },
  "from": "Z",
  "for": "Do",
  "commissioned_by": "Komisja przez",
  "planning_finished": "Planowanie zakończone",
  "sure_question": "Jesteś pewny?",
  "sure_question_cancel": "Anulujesz aktywny przystanek. Jesteś pewny?"
}