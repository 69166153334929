module.exports = {
  "language": "Sprog",
  "actors": "Skuespillere",
  "ID": "Id",
  "search": "Søg",
  "copied": "Kopieret til udklipsholder",
  "sort": "Sorter {0}",
  "yes": "Ja",
  "no": "Ingen",
  "notifications": {
    "title": "Underretninger",
    "empty": "Du har ingen meddelelser"
  },
  "auth": {
    "logout": "Log ud",
    "login": "Log på",
    "register": "Registrer ny konto"
  },
  "user": {
    "changePassword": "Skift kodeord",
    "firstPasswordChange": "Du skal ændre din adgangskode på første login",
    "plural": "Brugere",
    "single": "Bruger",
    "filters": {
      "locked": "Vis låste brugere"
    },
    "fields": {
      "password": "Adgangskode",
      "authorizations": "Godkendelser",
      "email": "E -mail",
      "name": "Navn",
      "repeatPassword": "Gentag adgangskode"
    },
    "validation": {
      "length": "8 tegn lange",
      "uppercase": "Et stort bogstav",
      "lowercase": "Et lille bogstav",
      "number": "Et nummer",
      "special": "En speciel karakter (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "telefon",
      "company": "Firmanavn",
      "street": "Gade",
      "zipCode": "Postnummer",
      "city": "By",
      "country": "Land",
      "houseNumber": "husnummer"
    }
  },
  "errors": {
    "loginWrong": "Brugernavn og adgangskode stemmer ikke overens. Prøv igen!",
    "notFound": {
      "title": "Siden blev ikke fundet",
      "message": "Ups! Denne side blev ikke fundet."
    },
    "noInternet": "\"Sie haben die Ladung ohne aktive Internetverbindung angenommen.\\nÜberprüfen Sie Ihre WLAN- oder mobilen Dateneinstellungen\\nWenn die Verbindung wiederhergestellt ist"
  },
  "messages": {
    "sureDelete": "Voulez-vous vraiment supprimer {0}?",
    "sureSave": "Voulez-vous vraiment enregistrer {0}?"
  },
  "tradelane": {
    "fields": {
      "sender": "Afsender",
      "receiver": "Modtager"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Nummerplade"
    }
  },
  "company": {
    "single": "Selskab"
  },
  "scan": {
    "currentStage": "Nuværende fase",
    "description": "Beskrivelse",
    "qrCode": "QR kode",
    "locationNotAccessible": "Placering ikke fundet",
    "noEmballage": "Ingen emballage",
    "noMessage": "Ingen besked tilføjet",
    "missingCargo": "Manglende last",
    "shipmentAndBarcodes": "Forsendelse og stregkoder",
    "scanPackages": "Scanpakker",
    "emballage": "Emballage",
    "required": "Krævet",
    "upload": "Upload",
    "statusRequirements": "Statuskrav",
    "addPhoto": "Tilføj foto",
    "addAttachments": "Tilføj vedhæftede filer",
    "addMessage": "Tilføj besked",
    "addEmballage": "Tilsæt emballage",
    "signOnGlass": "Underskriv glas",
    "start": "Start",
    "shelvesExtensions": "Hylder og udvidelser",
    "notready": "Ikke klar",
    "manco": "Mangler",
    "fields": {
      "message": "Besked",
      "attach": "Opret forbindelse",
      "detach": "Koble fra"
    },
    "changeAmounts": "Skift beløb",
    "damage": "Skade",
    "not": "Ikke",
    "mancoMessage": "Kunne ikke scanne stregkode eller pakke ikke til stede? Rapporter det på dette felt ..."
  },
  "sign": {
    "sign": "Skilt",
    "signAbsence": "Underskrevet i fravær af kunde"
  },
  "erp": {
    "single": "ERP",
    "packaging": "Emballage"
  },
  "nav": {
    "finished": "Færdig",
    "current": "Nuværende",
    "overview": "Oversigt",
    "messages": "Beskeder",
    "shipment": "Forsendelse",
    "shipments": "Forsendelser",
    "settings": "Indstillinger",
    "planning": "Joblist",
    "report": "Rapporter"
  },
  "wallet": {
    "single": "Tegnebog"
  },
  "common": {
    "info": "Info",
    "status": "Status",
    "location": "Beliggenhed",
    "contact": "Kontakt",
    "contacts": "Kontakter",
    "photos": "Fotos",
    "attachments": "Vedhæftede filer",
    "identifier": "Identifikator",
    "type": "Type",
    "actions": "Handlinger",
    "name": "Navn",
    "value": "Værdi",
    "tradelane": "Tradelane",
    "date": "Dato",
    "actor": "Skuespiller",
    "create": "skab",
    "from": "Fra",
    "to": "Til",
    "general": "Generel",
    "back": "Tilbage",
    "next": "Næste",
    "finish": "Afslut",
    "clear": "Klar",
    "notes": "Bemærkninger",
    "details": "detaljer",
    "retry": "Prøve igen",
    "amount": "Beløb",
    "trip": "Rejse",
    "references": "Referencer",
    "in": "I",
    "out": "Ud",
    "senderMessage": "Afsender Bemærk",
    "receiverMessage": "Modtagerens bemærkning",
    "total": "i alt"
  },
  "actions": {
    "shipmentCreated": "Forsendelse oprettet",
    "shipmentUpdated": "Forsendelse opdateret",
    "planningCreated": "Planlægning oprettet",
    "planningUpdated": "Planlægning opdateret",
    "accesscodeCreated": "Adgangskode oprettet",
    "accesscodeShared": "Adgangskode deles",
    "notificationSent": "Meddelelse sendt",
    "partOfAccesscodeCreated": "Element i oprettet adgangskode til planlægning",
    "partOfAccesscodeShared": "Element i delt adgangskode til planlægning",
    "addObj": "Tilføj {0}",
    "add": "Tilføje",
    "save": "Gemme",
    "saveObj": "Gem {0}",
    "copy": "Kopi",
    "send": "Sende",
    "continue": "Blive ved",
    "close": "Tæt"
  },
  "shipment": {
    "eta": "ETA",
    "ata": "VED EN",
    "single": "Forsendelse",
    "status": "Status",
    "finished": "Afsluttede forsendelser",
    "pickup": "Saml op",
    "delivery": "Levering",
    "delivered": "Levering",
    "pickedUp": "Leveret",
    "fields": {
      "parties": "Parter",
      "createdAt": "Oprettet kl",
      "updatedAt": "Opdateret kl"
    },
    "consignment": {
      "single": "Forsendelse",
      "scanned": {
        "waiting": "\"Traitement"
      }
    },
    "containers": {
      "fields": {
        "hsCode": "HS -kode",
        "packQuantity": "Pakkemængde",
        "packSize": "Pakningsstørrelse",
        "weight": "Vægt"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Indeholder farlige varer"
      }
    },
    "documents": {
      "fields": {
        "file": "Fil"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Fragtpris"
      }
    },
    "lastStatus": "Sidste status"
  },
  "accept": {
    "updateShipment": "Opdater forsendelse",
    "zeroEmballageMessage": "\"Du har ikke specificeret nogen emballage. Angiv en grund nedenfor"
  },
  "apiKeys": {
    "plural": "API -nøgler",
    "single": "API -nøgle",
    "fields": {
      "key": "Nøgle"
    }
  },
  "planning": {
    "driverInformation": "Driverinformation"
  },
  "from": "Fra",
  "for": "Til",
  "commissioned_by": "Kommissioner af",
  "planning_finished": "Planlægning færdig",
  "sure_question": "Er du sikker?",
  "sure_question_cancel": "Du annullerer et aktivt stop. Er du sikker?"
}