import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      component: () => import('./layouts/Desktop.vue'),
      name: 'home',
      children: [
        {
          path: 'users',
          component: () => import('./layouts/Container.vue'),
          children: [
            {
              path: '',
              name: 'user-overview',
              component: () => import('./modules/users/views/Overview.vue')
            },
            {
              path: 'create',
              name: 'user-create',
              component: () => import('./modules/users/views/CreateEdit.vue')
            },
            {
              path: ':id',
              name: 'user-edit',
              component: () => import('./modules/users/views/CreateEdit.vue')
            }
          ]
        },
        {
          path: 'shipments',
          component: () => import('./layouts/Container.vue'),
          children: [
            {
              path: '',
              name: 'shipment-overview',
              component: () => import('./modules/shipments/views/Overview.vue')
            },
            {
              path: 'shipment/:id',
              name: 'shipment',
              component: () => import('./modules/shipments/views/detail/index.vue')
            }
          ]
        },
        {
          path: 'ecmrs',
          component: () => import('./layouts/Container.vue'),
          children: [
            {
              path: '',
              name: 'ecmr-overview',
              component: () => import('./modules/ecmrs/views/Overview.vue')
            },
            {
              path: ':id',
              name: 'ecmr',
              component: () => import('./modules/ecmrs/views/Detail.vue')
            }
          ]
        },
        {
          path: 'plannings',
          component: () => import('./layouts/Container.vue'),
          children: [
            {
              path: '',
              name: 'planning-overview',
              component: () => import('./modules/planning/views/Overview.vue')
            },
            {
              path: ':id',
              name: 'planning',
              component: () => import('./modules/planning/views/Detail.vue')
            }
          ]
        },
        {
          path: 'messages',
          name: 'messages',
          component: () => import('./modules/messages/views/Messages.vue')
        },
        {
          path: 'report',
          name: 'report',
          component: () => import('./modules/report/Report.vue')
        },
        {
          path: 'map',
          name: 'map',
          component: () => import('./modules/map/Map.vue')
        }
      ]
    },
    {
      path: '',
      component: () => import('./layouts/Mobile.vue'),
      children: [
        {
          path: 'cmrs-list',
          name: 'cmrs-list',
          component: () => import('./modules/scan/views/CMRList.vue')
        },
        {
          path: 'scan',
          name: 'scan',
          component: () => import('./modules/scan/views/Scan.vue')
        },
        {
          path: 'scan/consignments',
          name: 'scanned-consignments',
          redirect: to => { return { name: 'all-plannings', query: { ...to.query } } }
        },
        {
          path: 'jobs',
          name: 'all-plannings',
          component: () => import('./modules/scan/views/ScanListPlannings.vue')
        },
        {
          path: 'jobs/:id',
          name: 'scan-planning',
          component: () => import('./modules/scan/views/ScanPlanning.vue')
        },
        {
          path: 'info/:consignment',
          component: () => import('./layouts/Container.vue'),
          children: [
            {
              path: '',
              name: 'scan-info',
              component: () => import('./modules/scan/views/ScanInfo.vue')
            },
            {
              path: 'tradelane',
              name: 'scan-tradelane',
              component: () => import('./modules/scan/views/ScanTradelane.vue')
            },
            {
              path: 'documents',
              name: 'scan-documents',
              component: () => import('./modules/scan/views/ScanDocuments.vue')
            },
            {
              path: 'qrcode',
              name: 'scan-qrcode',
              component: () => import('./modules/scan/views/ScanQrcode.vue')
            }
          ]
        },
        {
          path: 'ecmrs-mobile/:id',
          name: 'ecmrmobile',
          component: () => import('./modules/ecmrs/views/Detail.vue')
        }
      ]
    },
    {
      path: '',
      component: () => import('./layouts/Unauthorized.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('./general/views/Login.vue')
        },
        {
          path: 'forgotPassword',
          name: 'forgotPassword',
          component: () => import('./general/views/ForgotPassword.vue')
        }
      ]
    },
    { path: '*', component: () => import('./general/views/404.vue') }
  ]
})
