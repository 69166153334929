

















import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class All extends Vue {
  get snackbar() {
    return this.$store.state.snackbar
  }
}
