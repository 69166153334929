













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Search extends Vue {
  @Prop({ required: true }) service!: any

  searchString: string = ''

  created() {
    if (this.$route.query.search) {
      this.searchString = this.$route.query.search.toString()
      this.search()
    } else {
      this.updateUrl(this.searchString)
    }
  }

  updateUrl(event: string) {
    this.$router.replace({ query: { ...this.$route.query, search: event } })
  }

  search() {
    this.service(this.searchString)
  }
}
