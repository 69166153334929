module.exports = {
  "language": "Kalba",
  "actors": "Aktoriai",
  "ID": "Id",
  "search": "Paieška",
  "copied": "Nukopijuota į mainų sritį",
  "sort": "Rūšiuoti {0}",
  "yes": "Taip",
  "no": "Ne",
  "notifications": {
    "title": "Pranešimai",
    "empty": "Jūs neturite pranešimų"
  },
  "auth": {
    "logout": "Atsijungti",
    "login": "Prisijungti",
    "register": "Užregistruokite naują sąskaitą"
  },
  "user": {
    "changePassword": "Pakeisti slaptažodį",
    "firstPasswordChange": "Esimesel sisselogimisel peate oma parooli muutma",
    "plural": "Vartotojai",
    "single": "Vartotojas",
    "filters": {
      "locked": "Rodyti užrakintus vartotojus"
    },
    "fields": {
      "password": "Slaptažodis",
      "authorizations": "Leidimai",
      "email": "El. Paštas",
      "name": "vardas",
      "repeatPassword": "Pakartokite slaptažodį"
    },
    "validation": {
      "length": "8 simboliai ilgis",
      "uppercase": "Viena didžioji raidė",
      "lowercase": "Viena mažoji raidė",
      "number": "Vienas skaičius",
      "special": "Vienas specialus personažas (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Telefonas",
      "company": "Įmonės pavadinimas",
      "street": "Gatvė",
      "zipCode": "Pašto kodas",
      "city": "Miestas",
      "country": "Šalis",
      "houseNumber": "namo numeris"
    }
  },
  "errors": {
    "loginWrong": " prøv på nytt!\"",
    "notFound": {
      "title": "Puslapis nerastas",
      "message": "Oi! Šis puslapis nerastas."
    },
    "noInternet": "Du accepterade lasten utan en aktiv internetanslutning. \\ NPLEASE Kontrollera dina WiFi- eller mobildatainställningar."
  },
  "messages": {
    "sureDelete": "Er du sikker på at du vil slette {0}?",
    "sureSave": "Er du sikker på at du vil lagre {0}?"
  },
  "tradelane": {
    "fields": {
      "sender": "Siuntėjas",
      "receiver": "Imtuvas"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Valstybinis numeris"
    }
  },
  "company": {
    "single": "Įmonė"
  },
  "scan": {
    "currentStage": "Dabartinis etapas",
    "description": "apibūdinimas",
    "qrCode": "QR kodas",
    "locationNotAccessible": "Vieta nerasta",
    "noEmballage": "Nėra pakuotės",
    "noMessage": "Jokio pranešimo pridėta",
    "missingCargo": "Trūksta krovinio",
    "shipmentAndBarcodes": "Siunta ir brūkšniniai kodai",
    "scanPackages": "Nuskaitymo paketai",
    "emballage": "Emballage",
    "required": "Būtinas",
    "upload": "Įkelti",
    "statusRequirements": "Būsenos reikalavimai",
    "addPhoto": "Pridėti nuotrauką",
    "addAttachments": "Pridėkite priedus",
    "addMessage": "Pridėti pranešimą",
    "addEmballage": "Pridėkite embaliją",
    "signOnGlass": "Pasirašykite ant stiklo",
    "start": "Pradėti",
    "shelvesExtensions": "Lentynos ir pratęsimai",
    "notready": "Nepasirengusi",
    "manco": "Dingęs",
    "fields": {
      "message": "Pranešimas",
      "attach": "Prisijungti",
      "detach": "Atjungti"
    },
    "changeAmounts": "Pakeisti sumas",
    "damage": "Žala",
    "not": "Ne",
    "mancoMessage": "Kas vöötkoodi ega paketti ei saa skannida? Teatage sellest valdkonnas ..."
  },
  "sign": {
    "sign": "Pasirašyti",
    "signAbsence": "\"Pasirašytas"
  },
  "erp": {
    "single": "ERP",
    "packaging": "Pakuotė"
  },
  "nav": {
    "finished": "Baigtas",
    "current": "Dabartinis",
    "overview": "Apžvalga",
    "messages": "Pranešimai",
    "shipment": "Siuntimas",
    "shipments": "Siuntos",
    "settings": "Nustatymai",
    "planning": "Bedarbė",
    "report": "Pranešimai"
  },
  "wallet": {
    "single": "Piniginė"
  },
  "common": {
    "info": "Informacija",
    "status": "Būsena",
    "location": "Vieta",
    "contact": "kontaktas",
    "contacts": "Kontaktai",
    "photos": "Nuotraukos",
    "attachments": "Priedai",
    "identifier": "Identifikatorius",
    "type": "Tipas",
    "actions": "Veiksmai",
    "name": "vardas",
    "value": "Vertė",
    "tradelane": "Tradelane",
    "date": "Data",
    "actor": "Aktorius",
    "create": "Sukurti",
    "from": "Nuo",
    "to": "Į",
    "general": "Bendrasis",
    "back": "Atgal",
    "next": "Kitas",
    "finish": "Baigti",
    "clear": "Aišku",
    "notes": "Pastabos",
    "details": "Detalės",
    "retry": "Bandyti",
    "amount": "Suma",
    "trip": "Kelionė",
    "references": "Nuorodos",
    "in": "Į",
    "out": "",
    "senderMessage": "Siuntėjo pastaba",
    "receiverMessage": "Imtuvo pastaba",
    "total": "Iš viso"
  },
  "actions": {
    "shipmentCreated": "Sukurta siunta",
    "shipmentUpdated": "Atnaujinta siunta",
    "planningCreated": "Planavimas sukurtas",
    "planningUpdated": "Planavimas atnaujintas",
    "accesscodeCreated": "Sukurtas prieigos kodas",
    "accesscodeShared": "Prieigos kodas bendrinamas",
    "notificationSent": "Išsiųstas pranešimas",
    "partOfAccesscodeCreated": "Sukurto prieigos kodo elementas planuojant",
    "partOfAccesscodeShared": "Bendros prieigos kodo planavimo kodo elementas",
    "addObj": "Pridėti {0}",
    "add": "Papildyti",
    "save": "Sutaupyti",
    "saveObj": "Išsaugoti {0}",
    "copy": "Kopijuoti",
    "send": "Siųsti",
    "continue": "Tęsti",
    "close": "Uždaryti"
  },
  "shipment": {
    "eta": "ETA",
    "ata": "Ata",
    "single": "Siuntimas",
    "status": "Būsena",
    "finished": "Baigtos siuntos",
    "pickup": "Paimti",
    "delivery": "Pristatymas",
    "delivered": "Pristatymas",
    "pickedUp": "Pristatyta",
    "fields": {
      "parties": "Vakarėliai",
      "createdAt": "Sukurtas",
      "updatedAt": "Atnaujinta"
    },
    "consignment": {
      "single": " kaubasaadetis\"",
      "scanned": {
        "waiting": " voit jatkaa toisella lähetyksellä.\""
      }
    },
    "containers": {
      "fields": {
        "hsCode": "HS kodas",
        "packQuantity": "Pakuoti kiekį",
        "packSize": "Pakuotės dydis",
        "weight": "Svoris"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Sudėtyje yra pavojingų prekių"
      }
    },
    "documents": {
      "fields": {
        "file": "Failas"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Krovinio kaina"
      }
    },
    "lastStatus": "Paskutinė būsena"
  },
  "accept": {
    "updateShipment": "Atnaujinkite siuntą",
    "zeroEmballageMessage": "Et ole määrittänyt pakkauksia. Määritä alla oleva syy tai napsauta Valmis."
  },
  "apiKeys": {
    "plural": "API raktai",
    "single": "API raktas",
    "fields": {
      "key": "Raktas"
    }
  },
  "planning": {
    "driverInformation": "Vairuotojo informacija"
  },
  "from": "Nuo",
  "for": "Dėl",
  "commissioned_by": "Komisija",
  "planning_finished": "Planavimas baigtas",
  "sure_question": "Ar tu tuo tikras?",
  "sure_question_cancel": "Jūs atšaukiate aktyvią stotelę. Ar tu tuo tikras?"
}