module.exports = {
  "language": "Език",
  "actors": "Актьори",
  "ID": "документ за самоличност",
  "search": "Търсене",
  "copied": "Копиран в клипборда",
  "sort": "Сортиране {0}",
  "yes": "Да",
  "no": "Не",
  "notifications": {
    "title": "Известия",
    "empty": "Нямате известия"
  },
  "auth": {
    "logout": "Излез от профила си",
    "login": "Влизам",
    "register": "Регистрирайте нов акаунт"
  },
  "user": {
    "changePassword": "Промяна на паролата",
    "firstPasswordChange": "От вас се изисква да промените паролата си при първо вход",
    "plural": "Потребители",
    "single": "Потребител",
    "filters": {
      "locked": "Показване на заключени потребители"
    },
    "fields": {
      "password": "Парола",
      "authorizations": "Разрешения",
      "email": "електронна поща",
      "name": "Име",
      "repeatPassword": "Повтори паролата"
    },
    "validation": {
      "length": "8 знака дълги",
      "uppercase": "Една главна буква",
      "lowercase": "Една малка буква",
      "number": "Едно число",
      "special": "Един специален символ (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Телефон",
      "company": "Име на фирмата",
      "street": "Улица",
      "zipCode": "Пощенски код",
      "city": "Град",
      "country": "Държава",
      "houseNumber": "номера на къщата"
    }
  },
  "errors": {
    "loginWrong": "\"Потребителското име и паролата не съвпадат. Моля",
    "notFound": {
      "title": "Страницата не е намерена",
      "message": "Ами! Тази страница не беше намерена."
    },
    "noInternet": " wird die Annahme automatisch erneut gesendet\""
  },
  "messages": {
    "sureDelete": "\"Er du sikker på",
    "sureSave": "\"Er du sikker på"
  },
  "tradelane": {
    "fields": {
      "sender": "Подател",
      "receiver": "Приемник"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Регистрационен номер"
    }
  },
  "company": {
    "single": "Търговско дружество"
  },
  "scan": {
    "currentStage": "Текущ етап",
    "description": "Описание",
    "qrCode": "QR код",
    "locationNotAccessible": "Местоположение не е намерено",
    "noEmballage": "Без опаковки",
    "noMessage": "Няма добавено съобщение",
    "missingCargo": "Липсващ товар",
    "shipmentAndBarcodes": "Пратка и баркодове",
    "scanPackages": "Сканирайте пакети",
    "emballage": "Балаж",
    "required": "Задължително",
    "upload": "Качване",
    "statusRequirements": "Изисквания за състоянието",
    "addPhoto": "Добави снимка",
    "addAttachments": "Добави прикачени файлове",
    "addMessage": "Добавяне на съобщение",
    "addEmballage": "Добавете гама",
    "signOnGlass": "Подписвайте върху стъкло",
    "start": "Започнете",
    "shelvesExtensions": "Рафтове и разширения",
    "notready": "Не е готов",
    "manco": "Липсва",
    "fields": {
      "message": "Съобщение",
      "attach": "Свързване",
      "detach": "Изключете"
    },
    "changeAmounts": "Променете сумите",
    "damage": "Щета",
    "not": "Не",
    "mancoMessage": "Не може ли да сканира баркод или пакет? Докладвайте го в тази област ..."
  },
  "sign": {
    "sign": "Знак",
    "signAbsence": "Подписано при липса на клиент"
  },
  "erp": {
    "single": "Erp",
    "packaging": "Опаковане"
  },
  "nav": {
    "finished": "Завършен",
    "current": "Текущ",
    "overview": "Преглед",
    "messages": "Съобщения",
    "shipment": "Пратка",
    "shipments": "Пратки",
    "settings": "Настройки",
    "planning": "Joblist",
    "report": "Доклади"
  },
  "wallet": {
    "single": "Портфейл"
  },
  "common": {
    "info": "Информация",
    "status": "Статус",
    "location": "Местоположение",
    "contact": "Контакт",
    "contacts": "Контакти",
    "photos": "Снимки",
    "attachments": "Прикачени файлове",
    "identifier": "Идентификатор",
    "type": "Тип",
    "actions": "Действия",
    "name": "Име",
    "value": "Стойност",
    "tradelane": "Tradelane",
    "date": "Дата",
    "actor": "Актьор",
    "create": "Създаване",
    "from": "От",
    "to": "Да се",
    "general": "Общ",
    "back": "обратно",
    "next": "Следващия",
    "finish": "завършек",
    "clear": "Ясно",
    "notes": "Бележки",
    "details": "Подробности",
    "retry": "Повторение",
    "amount": "Количество",
    "trip": "Пътуване",
    "references": "Препратки",
    "in": "В",
    "out": "Навън",
    "senderMessage": "Забележка за подател",
    "receiverMessage": "Забележка за приемник",
    "total": "Обща сума"
  },
  "actions": {
    "shipmentCreated": "Създадена доставка",
    "shipmentUpdated": "Пратката актуализирана",
    "planningCreated": "Създадено планиране",
    "planningUpdated": "Планиране актуализирано",
    "accesscodeCreated": "Създаден код за достъп",
    "accesscodeShared": "Код за достъп споделен",
    "notificationSent": "Изпратено уведомление",
    "partOfAccesscodeCreated": "Елемент на създаден код за достъп за планиране",
    "partOfAccesscodeShared": "Елемент на споделения код за достъп за планиране",
    "addObj": "Добавете {0}",
    "add": "Добавяне",
    "save": "Запазете",
    "saveObj": "Запазете {0}",
    "copy": "копие",
    "send": "Изпратете",
    "continue": "продължи",
    "close": "Близо"
  },
  "shipment": {
    "eta": "Ета",
    "ata": "Ата",
    "single": "Пратка",
    "status": "Статус",
    "finished": "Финализирани пратки",
    "pickup": "Вдигни",
    "delivery": "Доставка",
    "delivered": "Доставка",
    "pickedUp": "Доставени",
    "fields": {
      "parties": "Партии",
      "createdAt": "Създаден на",
      "updatedAt": "Актуализирано на"
    },
    "consignment": {
      "single": "Пратка",
      "scanned": {
        "waiting": " veuillez patienter un instant. \\nOu continuez avec la prochaine expédition.\""
      }
    },
    "containers": {
      "fields": {
        "hsCode": "HS код",
        "packQuantity": "Количество на опаковката",
        "packSize": "Размер на опаковката",
        "weight": "Тегло"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Съдържа опасни стоки"
      }
    },
    "documents": {
      "fields": {
        "file": "Файл"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Товарна цена"
      }
    },
    "lastStatus": "Последен статус"
  },
  "accept": {
    "updateShipment": "Актуализиране на пратката",
    "zeroEmballageMessage": " eller klik på Finish.\""
  },
  "apiKeys": {
    "plural": "API клавиши",
    "single": "API ключ",
    "fields": {
      "key": "Ключ"
    }
  },
  "planning": {
    "driverInformation": "Информация за водача"
  },
  "from": "От",
  "for": "За",
  "commissioned_by": "Комиран от",
  "planning_finished": "Планиране приключи",
  "sure_question": "Сигурен ли си?",
  "sure_question_cancel": "Отменяте активна спирка. Сигурен ли си?"
}