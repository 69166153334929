module.exports = {
  "language": "Språk",
  "actors": "Skådespelare",
  "ID": "Id",
  "search": "Sök",
  "copied": "Kopierad till urklipp",
  "sort": "Sortera {0}",
  "yes": "Ja",
  "no": "Nej",
  "notifications": {
    "title": "Meddelanden",
    "empty": "Du har inga aviseringar"
  },
  "auth": {
    "logout": "Utloggning",
    "login": "Logga in",
    "register": "Registrera nytt konto"
  },
  "user": {
    "changePassword": "ändra lösenord",
    "firstPasswordChange": "Du måste ändra ditt lösenord vid första inloggningen",
    "plural": "Användare",
    "single": "Användare",
    "filters": {
      "locked": "Visa låsta användare"
    },
    "fields": {
      "password": "Lösenord",
      "authorizations": "Auktorisationer",
      "email": "E-post",
      "name": "namn",
      "repeatPassword": "repetera lösenord"
    },
    "validation": {
      "length": "8 tecken långa",
      "uppercase": "En stor bokstav",
      "lowercase": "Ett små bokstäver",
      "number": "Ett nummer",
      "special": "En speciell karaktär (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Telefon",
      "company": "Företagsnamn",
      "street": "Gata",
      "zipCode": "Postnummer",
      "city": "Stad",
      "country": "Land",
      "houseNumber": "hus nummer"
    }
  },
  "errors": {
    "loginWrong": "Username e password non corrispondono. Per favore riprova!",
    "notFound": {
      "title": "sidan hittas inte",
      "message": "hoppsan! Den här sidan hittades inte."
    },
    "noInternet": "Aceptó la carga sin una conexión a Internet activa. \\ Nlease verifique su wifi o configuración de datos móviles. \\ Nonce se restablece la conexión."
  },
  "messages": {
    "sureDelete": "Tem certeza de que deseja excluir {0}?",
    "sureSave": "Tem certeza de que deseja salvar {0}?"
  },
  "tradelane": {
    "fields": {
      "sender": "Avsändare",
      "receiver": "Mottagare"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Registreringsskylt"
    }
  },
  "company": {
    "single": "Företag"
  },
  "scan": {
    "currentStage": "Nuvarande nivå",
    "description": "Beskrivning",
    "qrCode": "QR -kod",
    "locationNotAccessible": "Plats hittades inte",
    "noEmballage": "Ingen förpackning",
    "noMessage": "Inget meddelande tillagd",
    "missingCargo": "Saknad last",
    "shipmentAndBarcodes": "Leverans och streckkoder",
    "scanPackages": "Spannpaket",
    "emballage": "Inbäddning",
    "required": "Nödvändig",
    "upload": "Ladda upp",
    "statusRequirements": "Statuskrav",
    "addPhoto": "Lägg till foto",
    "addAttachments": "Lägg till bilagor",
    "addMessage": "Lägg till meddelande",
    "addEmballage": "Lägga till pempallage",
    "signOnGlass": "Skylt på glas",
    "start": "Start",
    "shelvesExtensions": "Hyllor och tillägg",
    "notready": "Inte redo",
    "manco": "Saknas",
    "fields": {
      "message": "Meddelande",
      "attach": "Ansluta",
      "detach": "Koppla ifrån"
    },
    "changeAmounts": "Ändra belopp",
    "damage": "Skada",
    "not": "Inte",
    "mancoMessage": " не присутствующий? Сообщить об этом в этой области ...\""
  },
  "sign": {
    "sign": "Tecken",
    "signAbsence": "Undertecknad i avsaknad av kund"
  },
  "erp": {
    "single": "Erp",
    "packaging": "Förpackning"
  },
  "nav": {
    "finished": "Färdiga",
    "current": "Nuvarande",
    "overview": "Översikt",
    "messages": "Meddelanden",
    "shipment": "Sändning",
    "shipments": "Leveranser",
    "settings": "inställningar",
    "planning": "Jordrande",
    "report": "Rapporter"
  },
  "wallet": {
    "single": "Plånbok"
  },
  "common": {
    "info": "Info",
    "status": "Status",
    "location": "Plats",
    "contact": "Kontakt",
    "contacts": "Kontakter",
    "photos": "Foton",
    "attachments": "Bilagor",
    "identifier": "Identifierare",
    "type": "Typ",
    "actions": "Åtgärder",
    "name": "namn",
    "value": "Värde",
    "tradelane": "Varumärke",
    "date": "Datum",
    "actor": "Skådespelare",
    "create": "Skapa",
    "from": "Från",
    "to": "Till",
    "general": "Allmän",
    "back": "Tillbaka",
    "next": "Nästa",
    "finish": "Avsluta",
    "clear": "Klar",
    "notes": "Anteckningar",
    "details": "Information",
    "retry": "Försök igen",
    "amount": "Belopp",
    "trip": "Resa",
    "references": "Referenser",
    "in": "I",
    "out": "Ut",
    "senderMessage": "Avsändarens anmärkning",
    "receiverMessage": "Mottagarens kommentar",
    "total": "Total"
  },
  "actions": {
    "shipmentCreated": "Sändning skapad",
    "shipmentUpdated": "Leverans av leverans",
    "planningCreated": "Planering skapad",
    "planningUpdated": "Planering uppdaterad",
    "accesscodeCreated": "Åtkomstkod skapad",
    "accesscodeShared": "Åtkomstkod delad",
    "notificationSent": "Meddelande skickad",
    "partOfAccesscodeCreated": "Element i skapad åtkomstkod för planering",
    "partOfAccesscodeShared": "Element i delad åtkomstkod för planering",
    "addObj": "Lägg till {0}",
    "add": "Lägg till",
    "save": "Spara",
    "saveObj": "Spara {0}",
    "copy": "Kopiera",
    "send": "Skicka",
    "continue": "Fortsätta",
    "close": "Stänga"
  },
  "shipment": {
    "eta": "ETA",
    "ata": "Ata",
    "single": "Sändning",
    "status": "Status",
    "finished": "Slutförda transporter",
    "pickup": "Plocka upp",
    "delivery": "Leverans",
    "delivered": "Leverans",
    "pickedUp": "Levererad",
    "fields": {
      "parties": "Fester",
      "createdAt": "Skapad vid",
      "updatedAt": "Uppdaterad på"
    },
    "consignment": {
      "single": "Sändning",
      "scanned": {
        "waiting": "{id} salvati! \\ nStoring I dati possono richiedere un po 'di tempo. \\ Nyou può procedere con un'altra spedizione."
      }
    },
    "containers": {
      "fields": {
        "hsCode": "HS -kod",
        "packQuantity": "Packkvantitet",
        "packSize": "Paketstorlek",
        "weight": "Vikt"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Innehåller farliga varor"
      }
    },
    "documents": {
      "fields": {
        "file": "Fil"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "Fraktpris"
      }
    },
    "lastStatus": "Sista status"
  },
  "accept": {
    "updateShipment": "Uppdatera leverans",
    "zeroEmballageMessage": "Non hai specificato alcun packaging. Si prega di specificare un motivo di seguito o fare clic su Fine."
  },
  "apiKeys": {
    "plural": "API -nycklar",
    "single": "API -nyckel",
    "fields": {
      "key": "Nyckel"
    }
  },
  "planning": {
    "driverInformation": "Förarinformation"
  },
  "from": "Från",
  "for": "För",
  "commissioned_by": "Kommissionerad av",
  "planning_finished": "Planering klar",
  "sure_question": "Är du säker?",
  "sure_question_cancel": "Du avbryter ett aktivt stopp. Är du säker?"
}