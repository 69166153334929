
















import { Component, Mixins } from 'vue-property-decorator'
import InputMixin from '@/general/mixins/InputMixin'

@Component
export default class BaseAutocomplete extends Mixins(InputMixin) {}
