import { extend, localize } from 'vee-validate'
import { email, is, length, max, numeric, regex, required } from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import nl from 'vee-validate/dist/locale/nl.json'
import de from 'vee-validate/dist/locale/de.json'
import i18n from '@/i18n'

extend('required', required)
extend('email', email)
extend('numeric', numeric)
extend('max', max)
extend('length', length)
extend('regex', regex)
extend('is', is)
extend('minDate', {
  validate: (value: string, { min }: Record<string, any>) => new Date(value) > new Date(min),
  params: [
    {
      name: 'min'
    }
  ],
  message: (field: string) => i18n.t('errors.validation.minDate', { field }).toString()
})

localize({
  en,
  nl,
  de
})
