import { State as RootState } from './index'
import { Module } from 'vuex'

const STANDARD_TIMEOUT = 3000

class State {
  text = ''
  color = ''
  show = false
  time = STANDARD_TIMEOUT
}

const store: Module<State, RootState> = {
  state: new State(),
  namespaced: true,
  mutations: {
    show(state, { text, color, time = STANDARD_TIMEOUT }) {
      state.color = color
      state.text = text
      state.time = time
      state.show = true
    },
    close(state) {
      state.show = false
      state.time = STANDARD_TIMEOUT
    }
  },
  actions: {
    saved: ({ commit }) =>
      commit('show', {
        color: 'success',
        text: 'actions.saved'
      }),
    copied: ({ commit }) =>
      commit('show', {
        color: 'success',
        text: 'copied'
      }),
    sent: ({ commit }) =>
      commit('show', {
        color: 'success',
        text: 'Sent!'
      }),
    removed: ({ commit }) =>
      commit('show', {
        color: 'success',
        text: 'actions.deleted'
      }),
    error: ({ commit }) =>
      commit('show', {
        color: 'red',
        text: 'errors.somethingWrong'
      })
  }
}

export default store
