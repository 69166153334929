import Vue from 'vue'
import VueAxios from 'vue-axios'
import Axios from 'axios'
import { store } from '@/stores'
import { serialize } from '@/plugins/utils'

const axios = Axios.create({
  headers: { 'Content-Type': 'application/json' }
})
const tokenName = 'Authorization'
const accessTokenName = 'AccessCode'

axios.interceptors.request.use(config => {
  const token = sessionStorage.getItem(tokenName) || localStorage.getItem(tokenName)

  const params = new Proxy(new URLSearchParams(window.location.search), {
    //@ts-ignore
    get: (searchParams, prop) => searchParams.get(prop),
  });
  // @ts-ignore
  let accessCode = params.accessCode
  if (accessCode) {
    config.headers[accessTokenName] = accessCode
  } else if (token) {
    config.headers[tokenName] = token
  }

  if (config.data) {
    config.data = serialize(config.data)
  }

  return config
})

axios.interceptors.response.use(
  response => {
    const token = response.headers.authorization

    if (token) {
      let storage
      if (localStorage.getItem(tokenName)) {
        storage = localStorage
      } else if (sessionStorage.getItem(tokenName)) {
        storage = sessionStorage
      }
      if (storage) {
        const t = token.substring(7)
        store.commit('setToken', { token: t, storage: storage })
      }
    }

    return response
  },
  error => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(error)
    }

    // regex to test if we are requesting a consignment, since then we shouldn't be redirected to a 404 page,
    // want to stay at the scanning page
    const regex = new RegExp('^/api/consignments/.+/.[^/]+$')

    if (parseInt(error.response && error.response.status) === 404 && !regex.test(error.response.config.url)) {
      store.commit('notFound', true)
      // Prevent throwing this error upon no network
    } else if (JSON.stringify(error).indexOf('Error: Network Error') === 0) {
      store.dispatch('snackbar/error')
    }

    return Promise.reject(error)
  }
)

Vue.use(VueAxios, axios)

export default axios
