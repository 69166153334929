module.exports = {
  "language": "Språk",
  "actors": "Skuespillere",
  "ID": "Id",
  "search": "Søk",
  "copied": "Kopiert til utklippstavle",
  "sort": "Sorter {0}",
  "yes": "Ja",
  "no": "Nei",
  "notifications": {
    "title": "Varsler",
    "empty": "Du har ingen varsler"
  },
  "auth": {
    "logout": "Logg ut",
    "login": "Logg Inn",
    "register": "Registrer ny konto"
  },
  "user": {
    "changePassword": "Bytt passord",
    "firstPasswordChange": "Du må endre passordet ditt ved første pålogging",
    "plural": "Brukere",
    "single": "Bruker",
    "filters": {
      "locked": "Vis låste brukere"
    },
    "fields": {
      "password": "Passord",
      "authorizations": "Autorisasjoner",
      "email": "E -post",
      "name": "Navn",
      "repeatPassword": "Gjenta passord"
    },
    "validation": {
      "length": "8 tegn lang",
      "uppercase": "En stor bokstav",
      "lowercase": "En små bokstaver",
      "number": "Ett tall",
      "special": "En spesiell karakter (!@#$%^&*_ =+-)"
    }
  },
  "contact": {
    "fields": {
      "phone": "Telefon",
      "company": "Selskapsnavn",
      "street": "gate",
      "zipCode": "Post kode",
      "city": "By",
      "country": "Land",
      "houseNumber": "husnummer"
    }
  },
  "errors": {
    "loginWrong": "\"Имя пользователя и пароль не совпадают. Пожалуйста",
    "notFound": {
      "title": "Side ikke funnet",
      "message": "Ups! Denne siden ble ikke funnet."
    },
    "noInternet": "\"Você aceitou a carga sem uma conexão ativa à Internet. \\ Nupase Verifique suas configurações de Wi -Fi ou Dados Móveis. \\ Nonce A conexão é restabelecida"
  },
  "messages": {
    "sureDelete": "Sei sicuro di voler eliminare {0}?",
    "sureSave": "Sei sicuro di voler salvare {0}?"
  },
  "tradelane": {
    "fields": {
      "sender": "Avsender",
      "receiver": "Mottaker"
    }
  },
  "ecmr": {
    "single": "e-CMR",
    "fields": {
      "license": "Bilskilt"
    }
  },
  "company": {
    "single": "Selskap"
  },
  "scan": {
    "currentStage": "Nåværende trinn",
    "description": "Beskrivelse",
    "qrCode": "QR kode",
    "locationNotAccessible": "Plasseringen ikke funnet",
    "noEmballage": "Ingen emballasje",
    "noMessage": "Ingen melding lagt til",
    "missingCargo": "Mangler last",
    "shipmentAndBarcodes": "Forsendelse og strekkoder",
    "scanPackages": "Skanne pakker",
    "emballage": "Emballage",
    "required": "Påkrevd",
    "upload": "Laste opp",
    "statusRequirements": "Statuskrav",
    "addPhoto": "Legg til bildet",
    "addAttachments": "Legg til vedlegg",
    "addMessage": "Legg til melding",
    "addEmballage": "Legg til emballage",
    "signOnGlass": "Logg på glass",
    "start": "Start",
    "shelvesExtensions": "Hyller og utvidelser",
    "notready": "Ikke klar",
    "manco": "Savnet",
    "fields": {
      "message": "Beskjed",
      "attach": "Koble",
      "detach": "Koble fra"
    },
    "changeAmounts": "Endre beløp",
    "damage": "Skader",
    "not": "Ikke",
    "mancoMessage": "Kunde inte skanna streckkod eller paket inte närvarande? Rapportera det inom detta område ..."
  },
  "sign": {
    "sign": "Skilt",
    "signAbsence": "Signert i fravær av kunde"
  },
  "erp": {
    "single": "Erp",
    "packaging": "Emballasje"
  },
  "nav": {
    "finished": "Ferdig",
    "current": "Strøm",
    "overview": "Oversikt",
    "messages": "Meldinger",
    "shipment": "Forsendelse",
    "shipments": "Forsendelser",
    "settings": "Innstillinger",
    "planning": "Joblist",
    "report": "Rapporter"
  },
  "wallet": {
    "single": "Lommebok"
  },
  "common": {
    "info": "Info",
    "status": "Status",
    "location": "plassering",
    "contact": "Ta kontakt med",
    "contacts": "Kontakter",
    "photos": "Bilder",
    "attachments": "Vedlegg",
    "identifier": "Identifikator",
    "type": "Type",
    "actions": "Handlinger",
    "name": "Navn",
    "value": "Verdi",
    "tradelane": "Tradelane",
    "date": "Dato",
    "actor": "Skuespiller",
    "create": "Skape",
    "from": "Fra",
    "to": "Til",
    "general": "Generell",
    "back": "Tilbake",
    "next": "Neste",
    "finish": "Bli ferdig",
    "clear": "Klar",
    "notes": "Merknader",
    "details": "Detaljer",
    "retry": "Prøv på nytt",
    "amount": "Beløp",
    "trip": "Tur",
    "references": "Referanser",
    "in": "I",
    "out": "Ute",
    "senderMessage": "Avsenderanmerkning",
    "receiverMessage": "Mottaker -kommentar",
    "total": "Total"
  },
  "actions": {
    "shipmentCreated": "Forsendelse opprettet",
    "shipmentUpdated": "Forsendelse oppdatert",
    "planningCreated": "Planlegging opprettet",
    "planningUpdated": "Planlegging oppdatert",
    "accesscodeCreated": "Tilgangskode opprettet",
    "accesscodeShared": "Tilgangskode delt",
    "notificationSent": "Varsel sendt",
    "partOfAccesscodeCreated": "Element av opprettet tilgangskode for planlegging",
    "partOfAccesscodeShared": "Element i delt tilgangskode for planlegging",
    "addObj": "Legg til {0}",
    "add": "Legge til",
    "save": "Lagre",
    "saveObj": "Lagre {0}",
    "copy": "Kopiere",
    "send": "Sende",
    "continue": "Fortsette",
    "close": "Lukk"
  },
  "shipment": {
    "eta": "Eta",
    "ata": "Ata",
    "single": "Forsendelse",
    "status": "Status",
    "finished": "Avsluttet forsendelser",
    "pickup": "Plukke opp",
    "delivery": "Leveranse",
    "delivered": "Leveranse",
    "pickedUp": "Levert",
    "fields": {
      "parties": "Fester",
      "createdAt": "Opprettet på",
      "updatedAt": "Oppdatert på"
    },
    "consignment": {
      "single": "Sending",
      "scanned": {
        "waiting": "{id} Сохраняется!"
      }
    },
    "containers": {
      "fields": {
        "hsCode": "HS kode",
        "packQuantity": "Pakke mengde",
        "packSize": "Pakkestørrelse",
        "weight": "Vekt"
      }
    },
    "info": {
      "fields": {
        "hazardous": "Inneholder farlige varer"
      }
    },
    "documents": {
      "fields": {
        "file": "Fil"
      }
    },
    "selectTradelane": {
      "fields": {
        "freightPrice": "FREIGHET PRIS"
      }
    },
    "lastStatus": "Siste status"
  },
  "accept": {
    "updateShipment": "Oppdater forsendelse",
    "zeroEmballageMessage": "\"Вы не указали какую -либо упаковку. Пожалуйста"
  },
  "apiKeys": {
    "plural": "API -nøkler",
    "single": "API -nøkkel",
    "fields": {
      "key": "Nøkkel"
    }
  },
  "planning": {
    "driverInformation": "Driverinformasjon"
  },
  "from": "Fra",
  "for": "Til",
  "commissioned_by": "Pendlet av",
  "planning_finished": "Planleggingen er ferdig",
  "sure_question": "Er du sikker?",
  "sure_question_cancel": "Du kansellerer et aktivt stopp. Er du sikker?"
}