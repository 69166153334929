import { State as RootState } from './index'
import { Module } from 'vuex'
import {GetConsignment_GetConsignment} from "@/generated/GetConsignment";
import {
  ListSelfPlannings_ListSelfPlannings_items
} from "@/generated/ListSelfPlannings";
import { GetECMRSByPlanning_GetECMRSByPlanning_items_value } from '@/generated/GetECMRSByPlanning';
import {deleteAllPlanningUpdatedMessages} from "@/general/services/DatabaseService";


class State {
  consignment: GetConsignment_GetConsignment | null = null
  planning: ListSelfPlannings_ListSelfPlannings_items | null = null
  planningUpdated: number | null = null
  fetchingConsignment: boolean = false
  planningScroll: Map<number, PlanningState> = new Map<number, PlanningState>()
  ecmrs: Map<number, GetECMRSByPlanning_GetECMRSByPlanning_items_value[]> = new Map<number, GetECMRSByPlanning_GetECMRSByPlanning_items_value[]>()
  newEcmrs: boolean = false
}

export interface PlanningState {
  stop: number | undefined,
  expand: Array<Boolean>
}

const store: Module<State, RootState> = {
  state: new State(),
  namespaced: true,
  mutations: {
    set(state, consignment: GetConsignment_GetConsignment) {
      state.consignment = consignment
    },
    fetchingConsignment(state, fetching: boolean) {
      state.fetchingConsignment = fetching
    },
    setPlanningUpdated(state, updated: number) {
      state.planningUpdated = updated
    },
    clear(state) {
      state.consignment = null
    }
  },
  actions: {
    resetScan({ commit }) {
      commit('clear')
    },
    setPlanning({ commit, dispatch, state }, planning: ListSelfPlannings_ListSelfPlannings_items) {
      // Check if the planning that is set, is the same as our local planning.
      // If it's the same, check if the planning was updated since our last retrieval
      if (
          !state.planningUpdated && state.planning && (state.planning.id === planning.id)
          && (state.planning.updated === null && planning.updated !== null ||
              new Date(state.planning.updated) < new Date(planning.updated))
      ) {
        commit("transport/setPlanningUpdated", planning.id)
      }
      state.planning = planning
    },


    planningUpdatedFromWebsocket({ commit, dispatch, state }, planningUpdated: PlanningUpdated) {
      deleteAllPlanningUpdatedMessages().then()
      if (state.planning && (state.planning.id === planningUpdated.id) || (planningUpdated.currentUser && planningUpdated.email === planningUpdated.currentUser)) {
        commit("setPlanningUpdated", planningUpdated.id)
      }
    }
  }
}

interface PlanningUpdated {
  id: number
  email: string | null
  currentUser: string | null
}

export default store
