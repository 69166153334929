import Vue from 'vue'
import { store } from '@/stores'
import logger from 'vuex/dist/logger'

/**
 * Checks if the user has all given roles
 *
 * @param roles The roles that we check if available
 */
const hasRoles = (roles: string[]) => {
  if (!roles || roles.length === 0) {
    return true
  } else {
    if (store.state.user) {
      return roles.every((role: string) => store!!.state!!.user!!.authorities!!.includes(role))
    }
    return false
  }
}

const getColor = (color: string): string => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(`--${color}`)
    .trim()
}

const filterKey = (key: string): string => key.split('_').filter((str: string) => str)[0]

const validator = (model: any): any => {
  const validator: any = {}
  // const validators: FieldValidator[] = model.validator()
  // validators.forEach(vali => {
  //   if (vali.validator) {
  //     // @ts-ignore
  //     Object.keys(vali.validator).forEach(key => !vali.validator[key] && delete vali.validator[key])
  //     validator[vali.field] = serialize(vali.validator)
  //   }
  // })
  return validator
}

const deserialize = (original: any, update: any): any => {
  if (original && !Array.isArray(original) && typeof original === 'object') {
    const keys = Object.keys(original)
    if (keys) {
      keys.forEach((key: string) => {
        original[key] = deserialize(original[key], update[filterKey(key)])
      })
      return original
    }
  }
  return update
}

const serialize = (d: any) => {
  if (d) {
    const data = JSON.parse(JSON.stringify(d))
    if (Array.isArray(d)) {
      data.forEach((obj: any, index: number) => {
        data[index] = serialize(obj)
      })
    } else if (typeof d === 'object') {
      const keys = Object.keys(data)
      if (keys) {
        keys.forEach((key: string) => {
          const newKey = filterKey(key)
          data[newKey] = serialize(data[key])
          if (key !== newKey) {
            delete data[key]
          }
        })
      }
    }
    return data
  }
  return d
}

const utils = {
  hasRoles,
  getColor,
  validator,
  serialize,
  deserialize
}

Vue.prototype.$utils = utils

declare module 'vue/types/vue' {
  interface Vue {
    $utils: typeof utils
  }
}

export { hasRoles, getColor, validator, serialize, deserialize }
