<template>
  <ValidationProvider :name="compLabel" :rules="compValidator" :vid="id" v-slot="{ errors }">
    <v-file-input :data-vv-as="compLabel" :error-messages="errors" :id="id" :label="compLabel" :name="vid" v-bind="$attrs" v-on="$listeners" />
  </ValidationProvider>
</template>

<script>
import InputMixin from '@/general/mixins/InputMixin'

export default {
  mixins: [InputMixin]
}
</script>
