import { render, staticRenderFns } from "./BaseDataTable.vue?vue&type=template&id=9d6941be&scoped=true&"
import script from "./BaseDataTable.vue?vue&type=script&lang=ts&"
export * from "./BaseDataTable.vue?vue&type=script&lang=ts&"
import style0 from "./BaseDataTable.vue?vue&type=style&index=0&id=9d6941be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d6941be",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VDataTable,VLayout,VPagination})
